import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Grid, Icon } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SoftBadge from "components/SoftBadge";


const SoftPagination = forwardRef(
  ({ Totalpages, currentPage, changePage }, ref) => {

    return (
      <Grid container spacing={2} sx={{ marginBottom: 2, marginTop: 2, backgroundColor: '#ffffff' }}>

        <Grid xs={10} >
          <SoftBox bgColor="white" />
        </Grid>

        <Grid sx={{ cursor: 'pointer' }} entxs={0.6} >
          <SoftBadge
            variant="gradient"
            badgeContent={<ArrowBackIosNewIcon color="white" />}
            color="primary"
            size="md"
            onClick={() => currentPage !== 1 && changePage(currentPage - 1)}
            container
          />
        </Grid>

        <Grid xs={0.8} >
          <SoftBox
            padding={0.6}
            bgColor="white"
            height="max-content"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <SoftTypography color="black" fontSize="14px">
              {currentPage} of  {Totalpages}
            </SoftTypography>
          </SoftBox>
        </Grid>

        <Grid sx={{ cursor: 'pointer' }} entxs={0.6} >
          <SoftBadge
            variant="gradient"
            badgeContent={<ArrowForwardIosIcon color="white" />}
            color="primary"
            size="md"
            onClick={() => currentPage < Totalpages && changePage(currentPage + 1)}
            container
          />
        </Grid>

      </Grid >
    );
  }
);

SoftPagination.propTypes = {
  Totalpages: PropTypes.number,
  currentPage: PropTypes.number,
  changePage: PropTypes.func,
}

export default SoftPagination;
