import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { ReactComponent as DeleteProjectIcon } from "assets/images/small-logos/logo-deleteProject.svg";
import SoftBox from "components/SoftBox";
import api from "store/api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CircularLoader from "./Loader";

export default function DeleteProject() {
  const { store, setDialogue } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const {
    isOpen = false,
    projectId = "",
    is_deleted = true,
  } = store?.app?.dialogs?.deleteProject || {};

  const { success } = store?.app?.dialogs?.isSuccess || {};

  const handleClose = () =>
    setDialogue({ type: "deleteProject", isOpen: false });

  const handleDelete = async () => {
    setLoading(true);
    try {
      const deleteData = {
        projectId: projectId,
        type: "delete",
        is_deleted: is_deleted,
      };
      const response = await api.projectStatus(deleteData);

      handleClose();
      if (response.status == 1) {
        setDialogue({
          type: "isSuccess",
          success: !success,
        });
        toast.success(response?.message);
        navigate("/projects");
      }
    } catch (error) {
      console.error("Error updating project status:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            px: 14,
            pb: 2,
            pt: 3,
            margin: 0,
            borderRadius: "12px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ pb: 1.5 }}>
          <SoftTypography
            variant="h5"
            color="lightBlack"
            fontWeight="bold"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 16,
              alignItems: "center",
            }}
          >
            <SoftBox sx={{ display: "flex", position: "relative" }}>
              <DeleteProjectIcon style={{ width: "90px", height: "90px" }} />
            </SoftBox>
            {"Delete Project"}
          </SoftTypography>
        </DialogTitle>
        <DialogContent sx={{ px: 0 }}>
          <DialogContentText id="alert-dialog-description">
            <SoftTypography
              variant="h6"
              fontWeight="regular"
              textAlign="center"
              sx={{ color: "lightBlack.main" }}
            >
              Are you sure you want to delete project?
            </SoftTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            gap: 3,
            padding: "0px",
          }}
        >
          <SoftButton
            variant="gradient"
            color="error"
            sx={{
              width: "100%",
              padding: "14px 24px",
              fontSize: "16px",
            }}
            onClick={handleDelete}
          >
            {loading == true ? <CircularLoader type="small" /> : "Yes, Delete"}
          </SoftButton>
          <SoftTypography
            fontSize="16px"
            fontWeight="bold"
            align="center"
            color="primary"
            sx={{ margin: "0px !important", cursor: "pointer" }}
            onClick={handleClose}
          >
            Cancel
          </SoftTypography>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
