import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import StoreProvider from "./store/StoreProvider";
import { BrowserRouter } from "react-router-dom";
import { SoftUIControllerProvider } from "./context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ToastContainer position="top-right" autoClose={3000} />

    <BrowserRouter>
      <SoftUIControllerProvider>
        <StoreProvider>
          <App />
        </StoreProvider>
      </SoftUIControllerProvider>
    </BrowserRouter>
  </>
);
