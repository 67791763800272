import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { ReactComponent as DeleteProjectIcon } from "assets/images/small-logos/logo-deleteProject.svg";
import SoftBox from "components/SoftBox";
import api from "store/api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SoftInput from "components/SoftInput";
import CircularLoader from "./Loader";

export default function Addappdata({ type }) {
  const { store, setDialogue } = useStore();
  const navigate = useNavigate();
  const [text, setText] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const {
    isOpen = false,
    disciplineId,
    action,
    text: name,
    isEdit,
    inspectionTypeId,
    commentId,
  } = store?.app?.dialogs?.addAppdata || {};

  const { success } = store?.app?.dialogs?.isSuccess || {};

  React.useEffect(() => {
    setText(name);
  }, [isEdit]);

  const handleClose = () =>
    setDialogue({
      type: "addAppdata",
      isOpen: false,
      disciplineId: "",
      action: "",
      isEdit: false,
      text: "",
    });

  const handleAdd = async () => {
    setLoading(true);

    if (!text) {
      toast.error("Please enter the name");
      setLoading(false);
      return;
    }

    if (!isEdit) {
      try {
        const response =
          action == "discipline"
            ? await api.addDiscipline({
                name: text,
                type: "pre_defined",
              })
            : action == "inspectiontype"
            ? await api.addInspectiontype({
                name: text,
                discipline_id: disciplineId,
                type: "pre_defined",
              })
            : action == "comment"
            ? await api.addComment({
                comment: text,
                discipline_id: disciplineId,
                type: "pre_defined",
              })
            : "";

        if (response.status === 1) {
          setText("");
          toast.success(response?.message || "Appdata added successfully");
          setDialogue({
            type: "isSuccess",
            success: !success,
          });
          handleClose();
        } else {
          toast.error(response?.message || "Error In Add Discipline");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    } else if (isEdit) {
      try {
        const response =
          action == "discipline"
            ? await api.editDiscipline({
                name: text,
                discipline_id: disciplineId,
              })
            : action == "inspectiontype"
            ? await api.editInspectiontype({
                name: text,
                discipline_id: disciplineId,
                inspectionTypeId: inspectionTypeId,
              })
            : action == "comment"
            ? await api.editComment({
                comment: text,
                discipline_id: disciplineId,
                comment_id: commentId,
              })
            : "";

        if (response.status === 1) {
          setText("");
          toast.success(response?.message || "Appdata added successfully");
          setDialogue({
            type: "isSuccess",
            success: !success,
          });
          handleClose();
        } else {
          toast.error(response?.message || "Error In Add Discipline data");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="add-link-dialog-title"
        aria-describedby="add-link-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            px: 0,
            pb: 0,
            pt: 0,
            borderRadius: "12px",
            width: "400px",
          },
        }}
      >
        <DialogTitle
          style={{
            background: "#02255B",
            textAlign: "center",
            color: "#fff",
          }}
          id="add-link-dialog-title"
        >
          <SoftTypography
            style={{
              fontSize: "18px",
            }}
            variant="h5"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {action == "comment" && !isEdit
              ? "Add Pre-Defined Comment"
              : action == "discipline" && !isEdit
              ? `Add New ${action}`
              : action == "inspectiontype" && !isEdit
              ? `Add New ${action}`
              : `Update ${action}`}
          </SoftTypography>
        </DialogTitle>

        <DialogContent>
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pt={2}
            gap={2}
          >
            <SoftTypography
              variant="h6"
              fontWeight="regular"
              fontSize="12px"
              textAlign="center"
              sx={{ color: "lightBlack.main" }}
            >
              {action == "discipline" && !isEdit
                ? "You can add a new discipline by filling out the form here."
                : action == "inspectiontype" && !isEdit
                ? "You can add a new Inspection type by filling out the form here."
                : action == "comment" && !isEdit
                ? "You can add new pre-defined comment by filling out the form here."
                : `You can edit ${action} by edit the form here.`}
            </SoftTypography>

            <SoftInput
              fullWidth
              variant="outlined"
              placeholder={
                action == "discipline"
                  ? "Enter Discipline Name"
                  : action == "inspectiontype"
                  ? "Enter Inspection type"
                  : action == "comment"
                  ? "Enter Pre-Defined Comment"
                  : ""
              }
              value={text}
              name="text"
              onChange={(e) => {
                setText(e.target.value);
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  backgroundColor: "#F1F4F8 !important",
                  border: "none",
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
            />

            <SoftButton
              variant="gradient"
              color="primary"
              mb={0.5}
              sx={{
                width: "40%",
                padding: "10px 15px",
                fontSize: "16px",
              }}
              onClick={handleAdd}
            >
              {loading == true ? (
                <CircularLoader type="small" />
              ) : isEdit == true ? (
                "Update"
              ) : (
                "Add"
              )}
            </SoftButton>
          </SoftBox>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
