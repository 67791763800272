// @mui material components
import Card from "@mui/material/Card";
import React, { useState } from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import { MdFileUpload } from "react-icons/md";
import { FaUser } from "react-icons/fa6";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useParams, useNavigate } from "react-router-dom";
import { uploadImage } from "services/firebase";
import headerBg from "assets/images/curved-images/Header.png";
import Footer from "examples/Footer";
import api from "store/api/api";

const INITIAL_FORM_STATE = {
  fullName: "",
  email: "",
  image: "",
};

function Profile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [imageFile, setImageFile] = useState(null);

  const [form, setForm] = React.useState(INITIAL_FORM_STATE);

  React.useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await api.getUserDetail({ userId: id });
      if (response.status) {
        const userData = response.data;
        setForm({
          fullName: userData.full_name || "",
          email: userData.email || "",
          image: userData.profile_picture || null,
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleChange = (e) =>
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setForm(prev => ({ ...prev, image: URL.createObjectURL(file) }));
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      let imageUrl = form.image;
      if (imageFile) {
        imageUrl = await uploadImage(imageFile);
      }

      setTimeout(() => {
        navigate("/accounts");
      }, 100);
    } catch (error) {
      console.error("Failed to update profile:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const uploadImageClick = (e) => {
    e.preventDefault();
    document.getElementById("image-upload").click();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={1}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={{ xs: 2, md: 3 }}
              py={2.5}>
              <SoftTypography color="white" variant="h6" fontSize="20px">
                Profile
              </SoftTypography>
            </SoftBox>

            <SoftBox
              sx={{ px: { xs: 2, md: 14 }, pb: { xs: 4, md: 5 }, pt: 3 }}>
              <SoftBox>
                <SoftBox>
                  <Grid container direction="column">
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "center",
                      }}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="15px"
                        backgroundColor="white.main"
                        border="0.5px solid #02255B"
                        sx={{
                          height: { xs: "280px", md: "280px" },
                          width: { xs: "280px", md: "280px" },
                          overflow: "hidden",
                        }}>
                        {form.image ? (
                          <img
                            src={form.image}
                            alt="Profile"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "15px",
                            }}
                          />
                        ) : (
                          <FaUser size={190} color="#C1C1C1" />
                        )}
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          id="image-upload"
                          onChange={handleImageUpload}
                        />
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="center">
                        <label htmlFor="image-upload">
                          <SoftTypography
                            component="span"
                            fontWeight="bold"
                            fontSize="14px"
                            color="info"
                            display="flex"
                            alignItems="center"
                            gap={1}
                            sx={{ mt: 2, cursor: "pointer" }}
                            onClick={uploadImageClick}>
                            <MdFileUpload size="20px" />
                            Change Image
                          </SoftTypography>
                        </label>
                      </SoftBox>
                    </Grid>

                    <Grid item xs={12} pt={3}>
                      <SoftBox sx={{ maxWidth: "400px", margin: "0 auto" }}>
                        <SoftBox mb={2}>
                          <SoftInput
                            value={form.fullName}
                            name="fullName"
                            onChange={handleChange}
                            placeholder="Full Name"
                            icon={{
                              component: <PersonIcon />,
                              direction: "left",
                            }}
                            sx={{
                              padding: "16px 12px 16px 4px",
                              "& .MuiInputBase-input::placeholder": {
                                color: "#212121 !important",
                                fontSize: "16px",
                                opacity: 1,
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "16px",
                              },
                            }}
                          />
                        </SoftBox>
                        <SoftBox mb={2}>
                          <SoftInput
                            value={form.email}
                            name="email"
                            onChange={handleChange}
                            type="email"
                            placeholder="Email Address"
                            icon={{
                              component: <EmailIcon />,
                              direction: "left",
                            }}
                            sx={{
                              padding: "16px 12px 16px 4px",
                              "& .MuiInputBase-input::placeholder": {
                                color: "#212121 !important",
                                fontSize: "16px",
                                opacity: 1,
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "16px",
                              },
                            }}
                          />
                        </SoftBox>

                        <SoftBox display="flex" gap={2} justifyContent="center">
                          <SoftButton
                            variant="gradient"
                            color="primary"
                            fullWidth
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                            sx={{
                              padding: "14px 24px",
                              mt: 1,
                              width: "75%",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}>
                            {isSubmitting ? "Saving..." : "Save Changes"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Profile;
