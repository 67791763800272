/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard React layouts
import SignIn from "layouts/authentication/sign-in";
import ForgotPassword from "layouts/authentication/forgot-password";
import Accounts from "layouts/tables";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { FaFileAlt } from "react-icons/fa";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NewPassword from "layouts/authentication/new-password";
import OTPVerify from "layouts/authentication/otp-verify";
import Projects from "layouts/tables/Projects";
import Reports from "layouts/tables/Reports";
import Appdata from "layouts/tables/Appdata";
import ChangePassword from "layouts/changePassword";
import Profile from "layouts/profile";
import Settings from "layouts/settings";
import ProjectOverview from "layouts/projectOverview";
import InspectionOverview from "layouts/inspectionOverview";
import InspectorOverview from "layouts/inspectorOverview";
import PasswordChange from "layouts/inspectorOverview/passwordChange";
import ProjectForm from "layouts/projectForm";
import AccountForm from "layouts/accountForm";
import UpdateInspection from "layouts/updateInspection";
import { Description } from "@mui/icons-material";

const routes = [
  {
    type: "collapse",
    name: "Accounts",
    key: "accounts",
    route: "/accounts",
    icon: <AccountCircleIcon />,
    component: <Accounts />,
    noCollapse: true,
    title: "Accounts",
    activePaths: [
      "/inspector-overview",
      "/inspectors/password-change"
    ],
    shouldRender: () => {
      const loginData = JSON.parse(localStorage.getItem("loginData"));
      return true ;
    }
  },
  
  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    route: "/projects",
    icon: <FaFileAlt />,
    component: <Projects />,
    noCollapse: true,
    title: "Projects",
    activePaths: [
      "/project-overview",
    ]
  },
  {
    type: "collapse",
    name: "Inspections",
    key: "inspections",
    route: "/inspections",
    icon: <FileCopyIcon />,
    component: <Reports />,
    noCollapse: true,
    title: "Inspections",
    activePaths: [
      "/inspection-overview",
      "/update-inspection"
    ]
  },
  {
    type: "collapse",
    name: "Appdata",
    key: "appdata",
    route: "/appdata",
    icon: <Description />,
    component: <Appdata />,
    noCollapse: true,
    title: "App Data",
    activePaths: [
      "/appdata"
    ]
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: <SettingsOutlinedIcon />,
    component: <Settings />,
    noCollapse: true,
    title: "Settings"
  },
  {
    type: "hidden",
    name: "Add Account",
    key: "add-account",
    route: "/accounts/add-account",
    icon: "",
    component: <AccountForm mode="add" />,
    noCollapse: true,
    title: "Accounts"
  },
  {
    type: "hidden",
    name: "Edit Account",
    key: "edit",
    route: "/accounts/edit/:id",
    icon: "",
    component: <AccountForm mode="edit" />,
    noCollapse: true,
    title: "Accounts"
  },
  {
    type: "hidden",
    name: "Change Password",
    key: "change-password",
    route: "/accounts/change-password/:id",
    icon: "",
    component: <ChangePassword />,
    noCollapse: true,
    title: "Accounts"
  },
  {
    type: "hidden",
    name: "Profile",
    key: "profile",
    route: "/accounts/profile/:id",
    icon: "",
    component: <Profile />,
    noCollapse: true,
    title: "Accounts"
  },
  {
    type: "hidden",
    name: "Add Project",
    key: "add-project",
    route: "/projects/add-project",
    icon: "",
    component: <ProjectForm mode="add" />,
    noCollapse: true,
    title: "Projects"
  },
  {
    type: "hidden",
    name: "Edit Project",
    key: "edit",
    route: "/projects/edit/:id",
    icon: "",
    component: <ProjectForm mode="edit" />,
    noCollapse: true,
    title: "Projects"
  },
  {
    type: "hidden",
    name: "Project Overview",
    key: "project-overview",
    route: "/project-overview/:id",
    icon: "",
    component: <ProjectOverview />,
    noCollapse: true,
    title: "Projects"
  },
  {
    type: "hidden",
    name: "Inspection Overview",
    key: "inspection-overview",
    route: "/inspection-overview/:id",
    icon: "",
    component: <InspectionOverview />,
    noCollapse: true,
    title: "Inspections"
  },

  {
    type: "hidden",
    name: "Inspection Update",
    key: "inspection-update",
    route: "/update-inspection/:id",
    icon: "",
    component: <UpdateInspection />,
    noCollapse: true,
    title: "Inspections"
  },

  {
    type: "hidden",
    name: "Inspector Overview",
    key: "inspector-overview",
    route: "/inspector-overview/:id",
    icon: "",
    component: <InspectorOverview />,
    noCollapse: true,
    title: "Accounts"
  },
  {
    type: "hidden",
    name: "Password Change",
    key: "password-change",
    route: "/inspectors/password-change/:id",
    icon: "",
    component: <PasswordChange />,
    noCollapse: true,
    title: "Accounts"
  },
  {
    type: "title",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: "",
    component: <SignIn />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "Forgot Password",
    key: "forgot-password",
    route: "/authentication/forgot-password",
    icon: "",
    component: <ForgotPassword />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "New Password",
    key: "new-password",
    route: "/authentication/new-password",
    icon: "",
    component: <NewPassword />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "OTP Verification",
    key: "otp-verify",
    route: "/authentication/otp-verify",
    icon: "",
    component: <OTPVerify />,
    noCollapse: true,
  },
];

export const getPageTitle = (pathname) => {
  const route = routes.find(route => {
    const pattern = route.route.replace(/:\w+/g, '[^/]+');
    const isMainPath = new RegExp(`^${pattern}$`).test(pathname);
    const isActivePath = route.activePaths?.some(path => pathname.startsWith(path));
    return isMainPath || isActivePath;
  });
  return route?.title || 'Accounts';
};

export default routes;
