import * as React from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Dialog, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import useStore from "store/hook";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

dayjs.extend(customParseFormat); // Enable custom format parsing

export default function TimePickerWithButtons({
  arrivalTime,
  setArrivalTime,
  leftTime,
  setLeftTime,
  inspectionCreateTime,
  setInspectionCreateTime,
}) {
  const { store, setDialogue } = useStore();
  const { isOpen = false, action } = store?.app?.dialogs?.openTimePicker || {};

  let initialTime =
    action === "arrival"
      ? Number(arrivalTime * 1000)
      : action === "left"
      ? Number(leftTime * 1000)
      : action === "inspectiontime"
      ? Number(inspectionCreateTime * 1000)
      : dayjs();

  const [tempTime, setTempTime] = React.useState(dayjs(initialTime));

  React.useEffect(() => {
      if (initialTime && isOpen) {
        setTempTime(dayjs(initialTime));
      }
    }, [initialTime]);

  const handleClose = () =>
    setDialogue({ type: "openTimePicker", isOpen: false });

  const handleConfirm = () => {
    const selectedTimeInMs = tempTime.valueOf();

    if (action == "arrival") {
      setArrivalTime(Math.floor(selectedTimeInMs / 1000));
    } else if (action == "left") {
      setLeftTime(Math.floor(selectedTimeInMs / 1000));
    } else if (action == "inspectiontime") {
      
      setInspectionCreateTime(Math.floor(selectedTimeInMs / 1000));
    }

    initialTime = dayjs();
    setTempTime(dayjs());
    handleClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={isOpen} onClose={handleClose}>
        <Typography variant="h6" sx={{ textAlign: "center", marginBottom: 1 }}>
          {action === "arrival" ? "Select Arrival Time" : action == "left"? "Select Left Time" : "select Inspection Time"}
        </Typography>

        <StaticTimePicker
          value={tempTime}
          onChange={(newTime) => setTempTime(newTime)}
          onAccept={handleConfirm}
          onClose={handleClose}
          ampm
          slotProps={{
            actionBar: {
              actions: ["cancel", "accept"],
              sx: {
                "& .MuiButton-root": { color: "#02255B" },
                "& .MuiButton-text": { fontWeight: "bold !important" },
                "& .MuiDialogActions-root button:nth-of-type(1)": {
                  color: "#FF0000",
                },
                "& .MuiDialogActions-root button:nth-of-type(2)": {
                  color: "#00AA00",
                },
              },
            },
            layout: {
              sx: {
                "& .MuiTimePickerToolbar-root": {
                  padding: "10px",
                },
                "& .MuiTypography-root": {
                  fontSize: "20px",
                  fontWeight: "bold",
                  textAlign: "center",
                },
              },
            },
          }}
        />
      </Dialog>
    </LocalizationProvider>
  );
}
