import axios from "axios";
import { uploadImage } from "services/firebase";
import {
  BASE_URL,
  API_KEY,
  LOGIN,
  GET_USERS,
  GET_USER_DETAIL,
  GET_PROJECTS,
  GET_REPORTS,
  GET_PROJECT_DETAIL,
  ADD_PROJECT,
  ADD_ACCOUNT,
  SEND_FORGOT_OTP,
  VERIFY_OTP,
  CHANGE_FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  UPDATE_USER_STATUS,
  UPDATE_USER_DETAIL,
  EDIT_PROJECT_DETAIL,
  GET_INSPECTORS_LIST,
  PROJECT_STATUS,
  MARK_AS_COMPLETE,
  SEARCH_INSPECTOR_LIST,
  GET_INSPECTIONS,
  GET_INSPECTION_DETAIL,
  INSPECTION_STATUS,
  REOPEN_PROJECT,
  GET_INSPECTION_BYPROJECT,
  ADD_DICCIPLINE,
  GET_DISCIPLINEDATA,
  ADD_INSPECTIONTYPE,
  ADD_COMMENT,
  CHANGE_INPECTION_STATUS,
  UPDATE_INSPECTION,
  EDIT_DISCIPLINE,
  EDIT_INSPECTIONTYPE,
  EDIT_COMMENT,
  DELETE_COMMENT,
  DELETE_INSPECTIONTYPE,
  DELTED_DISCIPLINE,
  COMPLETE_INSPECTION
} from "./apiConstants";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    apiKey: API_KEY,
  },
});

const axiosInstanceAfterLogin = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    apiKey: API_KEY,
    authorization: localStorage.getItem("loginData")
      ? JSON.parse(localStorage.getItem("loginData")).token
      : "",
  },
});

const api = {
  login: async (userData) => {
    try {
      const response = await axiosInstance.post(LOGIN, userData);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error logging in:", error);
      throw error; 
    }
  },
  sendForgotOtp: async (data) => {
    try {
      const response = await axiosInstance.post(SEND_FORGOT_OTP, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error sending forgot OTP:", error);
      throw error;
    }
  },
  verifyOtp: async (data) => {
    try {
      const response = await axiosInstance.post(VERIFY_OTP, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error verifying OTP:", error);
      throw error;
    }
  },
  changeForgotPassword: async (data) => {
    try {
      const response = await axiosInstance.post(CHANGE_FORGOT_PASSWORD, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error changing forgot password:", error);
      throw error;
    }
  },
  getUsersList: async (filterData) => {
    try {
      const response = await axiosInstanceAfterLogin.post(GET_USERS, filterData);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error fetching users:", error);
      throw error; 
    }
  },
  getUserDetail: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(GET_USER_DETAIL, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error fetching users:", error);
      throw error; 
    }
  },
  getProjectsList: async (filterData) => {
    try {
      const response = await axiosInstanceAfterLogin.post(GET_PROJECTS, filterData);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error fetching users:", error);
      throw error; // Rethrow error to handle in components
    }
  },
  getReportsList: async (filterData) => {
    try {
      const response = await axiosInstanceAfterLogin.post(GET_REPORTS, filterData);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error fetching reports:", error);
      throw error; 
    }
  },
  getProjectDetail: async (filterData) => {
    try {
      const response = await axiosInstanceAfterLogin.post(GET_PROJECT_DETAIL, filterData);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },

  getInspectionDetails: async (filterData) => {
      try{
        const response = await axiosInstanceAfterLogin.post(GET_INSPECTION_DETAIL, filterData);
        if(response.status !== 200){
          const errorMessage = `Error: ${response.statusText} (${response.status})`;
          alert(errorMessage);
          throw new Error(errorMessage);
        }
        return response.data;
      }
      catch(error){
        console.error("Error fetching users:", error);
        throw error;
      }
  },

  addProject: async (projectData) => {
    try {
      let dataToSubmit = { ...projectData };

      if (projectData.links) {
        dataToSubmit.links = JSON.stringify(projectData.links);
      }

      if (projectData.site_contacts) {
        dataToSubmit.site_contacts = JSON.stringify(projectData.site_contacts);
      }

      if(projectData.manager) {
        dataToSubmit.manager = JSON.stringify(projectData.manager);
      }

      const response = await axiosInstanceAfterLogin.post(ADD_PROJECT, dataToSubmit);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error adding project:", error);
      throw error;
    }
  },
  addAccount: async (accountData) => {
    try {
      let dataToSubmit = { ...accountData };

      if (accountData.profile_picture) {
        const uploadImageUrl = await uploadImage(accountData.profile_picture);
        dataToSubmit.profile_picture = uploadImageUrl;
      }

      const response = await axiosInstanceAfterLogin.post(ADD_ACCOUNT, dataToSubmit);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error adding account:", error);
      throw error;
    }
  },
  changePassword: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(CHANGE_PASSWORD, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error changing password:", error);
      throw error;
    }
  },
  updateUserStatus: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(UPDATE_USER_STATUS, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error updating user status:", error);
      throw error;
    }
  },
  updateUserDetail: async (data) => {
    try {
      let dataToSubmit = { ...data };
      if (data.profile_picture instanceof File) {
        const uploadImageUrl = await uploadImage(data.profile_picture);
        dataToSubmit.profile_picture = uploadImageUrl;
      }

      const response = await axiosInstanceAfterLogin.post(UPDATE_USER_DETAIL, dataToSubmit);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error updating user detail:", error);
      throw error;
    }
  },
  editProjectDetail: async (projectData) => {
    try {
      let dataToSubmit = { ...projectData };

      if (projectData.links) {
        dataToSubmit.links = JSON.stringify(projectData.links);
      }

      if (projectData.site_contacts) {
        dataToSubmit.site_contacts = JSON.stringify(projectData.site_contacts);
      }

      if(projectData.manager) {
        dataToSubmit.manager = JSON.stringify(projectData.manager);
      }

      const response = await axiosInstanceAfterLogin.post(EDIT_PROJECT_DETAIL, dataToSubmit);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error editing project detail:", error);
      throw error;
    }
  },
  getInspectorsList: async (filterData) => {
    try {
      const response = await axiosInstanceAfterLogin.post(GET_INSPECTORS_LIST, filterData);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error fetching inspectors:", error);
      throw error; 
    }
  },
  projectStatus: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(PROJECT_STATUS, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error updating user status:", error);
      throw error;
    }
  },

  inspectionStatus: async(data) => {

    try {
      const response = await axiosInstanceAfterLogin.post(INSPECTION_STATUS, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error updating user status:", error);
      throw error;
    }

  },

  changeInspectionStatus: async(data) => {

    try {
      const response = await axiosInstanceAfterLogin.post(CHANGE_INPECTION_STATUS, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error in update the status of  Inspection status:", error);
      throw error;
    }

  },
  completeInspectionStatus: async(data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(COMPLETE_INSPECTION, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error in update the status of  Inspection status:", error);
      throw error;
    }
  },
  markAsComplete: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(MARK_AS_COMPLETE, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error marking as complete:", error);
      throw error;
    }
  },

  reopenProject: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(REOPEN_PROJECT, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error in reopen project:", error);
      throw error;
    }
  },

  searchInspectorList: async (filterData) => {
    try {
      const response = await axiosInstanceAfterLogin.post(SEARCH_INSPECTOR_LIST, filterData);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error searching inspectors:", error);
      throw error; 
    }
  },

  getInspectionList: async (filterData) => {
    try {
      const response = await axiosInstanceAfterLogin.post(GET_INSPECTIONS, filterData);

      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error fetching Inspections:", error);
      throw error; // Rethrow error to handle in components
    }
  },

  getInspectionListByProject: async (filterData) => {
    try {
      const response = await axiosInstanceAfterLogin.post(GET_INSPECTION_BYPROJECT, filterData);

      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error fetching project Inspections:", error);
      throw error; // Rethrow error to handle in components
    }
  },

  addDiscipline: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(ADD_DICCIPLINE, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error in add Discipline:", error);
      throw error; 
    }
  },

  editDiscipline: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(EDIT_DISCIPLINE, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error in Edit Discipline:", error);
      throw error; 
    }
  },

  addInspectiontype: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(ADD_INSPECTIONTYPE, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error in add Location:", error);
      throw error; 
    }
  },

  editInspectiontype: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(EDIT_INSPECTIONTYPE, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error in Edit Discipline Location:", error);
      throw error; 
    }
  },

  addComment: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(ADD_COMMENT, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error in add Comment:", error);
      throw error; 
    }
  },

  editComment: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(EDIT_COMMENT, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error in Edit Discipline Comment:", error);
      throw error; 
    }
  },

  editInspection: async (InspectionData) => {
    try {
      let dataToSubmit = { ...InspectionData };

      if (InspectionData.discipline_data) {
        dataToSubmit.discipline_data = JSON.stringify(InspectionData.discipline_data);
      }

      const response = await axiosInstanceAfterLogin.post( UPDATE_INSPECTION, dataToSubmit);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      console.error("Error in updating the Inspection detail:", error);
      throw error;
    }
  },

  getDisciplineData: async () => {
    try {
      const response = await axiosInstanceAfterLogin.post(GET_DISCIPLINEDATA);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error in get Discipline data:", error);
      throw error; 
    }
  },
  
  deleteInspectionType: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(DELETE_INSPECTIONTYPE, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error in Delete Discipline Location:", error);
      throw error; 
    }
  },

  deleteComment: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(DELETE_COMMENT, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error in Delete Discipline Comment:", error);
      throw error; 
    }
  },

  deleteDiscipline: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(DELTED_DISCIPLINE, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      alert(error);
      console.error("Error in Delete Discipline:", error);
      throw error; 
    }
  },
  
  
};

export default api;





