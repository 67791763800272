import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { ReactComponent as RemoveInspectorIcon } from "assets/images/small-logos/logo-removeInspector.svg";
import SoftBox from "components/SoftBox";

export default function RemoveInspector() {
  const { store, setDialogue } = useStore();

  const {
    isOpen = false,
    userId = "",
    is_deleted = true,
  } = store?.app?.dialogs?.removeInspector || {};

  const handleClose = () =>
    setDialogue({ type: "removeInspector", isOpen: false });

  const handleDelete = () => {
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            px: 10,
            pb: 2,
            pt: 3,
            margin: 0,
            borderRadius: "12px",
            width: "540px",
          },
        }}>
        <DialogTitle id="alert-dialog-title" sx={{ pb: 1.5 }}>
          <SoftTypography
            variant="h5"
            color="lightBlack"
            fontWeight="bold"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 16,
              alignItems: "center",
            }}>
            <SoftBox sx={{ display: "flex", position: "relative" }}>
              <RemoveInspectorIcon style={{ width: "90px", height: "90px" }} />
            </SoftBox>
            {"Remove Inspector"}
          </SoftTypography>
        </DialogTitle>
        <DialogContent sx={{ px: 0 }}>
          <DialogContentText id="alert-dialog-description">
            <SoftTypography
              variant="h6"
              fontWeight="regular"
              textAlign="center"
              sx={{ color: "lightBlack.main" }}>
              Are you sure you want to Remove Inspector?
            </SoftTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            gap: 3,
            padding: "0px",
          }}>
          <SoftButton
            variant="gradient"
            color="error"
            sx={{
              width: "100%",
              padding: "14px 24px",
              fontSize: "16px",
              maxWidth: "330px",
            }}
            onClick={handleDelete}>
            Yes, Remove
          </SoftButton>
          <SoftTypography
            fontSize="16px"
            fontWeight="bold"
            align="center"
            color="primary"
            sx={{ margin: "0px !important", cursor: "pointer" }}
            onClick={handleClose}>
            Cancel
          </SoftTypography>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
