import React from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import convertMillisecondsToDate from "../../services/dateconverter";
import formatStringWithSpaces from "../../services/stringconverter";
import EditIcon from "@mui/icons-material/Edit";
import truncateString from "../../services/truncatestring";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

export const columns = [
  { name: "P.I.F.", align: "left" },
  { name: "Inspector", align: "left" },
  { name: "Date & Time", align: "left" },
  { name: "Weather", align: "left" },
  { name: "Deficiency found", align: "center" },
  { name: "Status", align: "left" },
  { name: "Action", align: "center" },
];

function Rows(data, setDialogue) {

  return {
    linkPath: `/inspection-overview/${data._id}`,
    noLinkCells: ["Action"],
    "P.I.F.": (
      <Link to={`/inspection-overview/${data._id}`}>
        <SoftTypography
          variant="text"
          color="lightBlack"
          fontWeight="regular"
          textTransform="capitalize"
          fontSize="14px"
          padding="15px 0px 15px 14px !important"
          sx={{
            display: "block",
            whiteSpace: "normal",
            wordWrap: "break-word",
            maxWidth: "200px",
            lineHeight: "1.5em",
          }}
        >
          <SoftTypography
            variant="text"
            color="lightBlack"
            fontWeight="bold"
            textTransform="capitalize"
            fontSize="14px"
            sx={{
              display: "block",
              whiteSpace: "normal",
              wordWrap: "break-word",
              maxWidth: "200px",
            }}
          >
            #{data?.project_number}
          </SoftTypography>
          {data?.project_name}
        </SoftTypography>
      </Link>
    ),
    Inspector: (
      <SoftTypography
        variant="text"
        color="lightBlack"
        fontWeight="medium"
        fontSize="16px"
        textTransform="capitalize"
        sx={{
          display: "block",
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "200px",
        }}
      >
        {data?.added_by?.full_name}
      </SoftTypography>
    ),

    Weather: (
      <SoftTypography
        variant="text"
        color="lightBlack"
        fontWeight="medium"
        fontSize="16px"
        textTransform="uppercase"
        display="inline-flex"
        alignItems="center"
      >
        {`${data?.weather_condition} ${data?.temperature}`}
        <SoftTypography fontWeight="dark">
          <PanoramaFishEyeIcon
            sx={{
              marginLeft: "-1px",
              marginBottom: "12px",
              width: 7,
              height: 7,
              filter: "brightness(0) contrast(9)",
            }}
          />
        </SoftTypography>
        F
      </SoftTypography>
    ),

    "Deficiency found": (
      <SoftTypography
        variant="text"
        color="lightBlack"
        fontWeight="medium"
        fontSize="16px"
        textTransform="uppercase"
      >
        {formatStringWithSpaces(data.deficiencies_found.join(", "))}
      </SoftTypography>
    ),

    "Date & Time": (
      <SoftBox display="flex" flexDirection="column" alignItems="center">
        <SoftTypography
          variant="text"
          color="lightBlack"
          fontWeight="regular"
          textTransform="none"
          fontSize="14px"
          maxWidth="100px"
          sx={{
            color: "#02255B",
          }}
        >
          {convertMillisecondsToDate(data.createdTime)}
        </SoftTypography>
      </SoftBox>
    ),
    Status: (
      <SoftTypography
        variant="text"
        color="lightBlack"
        fontWeight="medium"
        textTransform="capitalize"
        padding="18px 0px !important"
        sx={{
          display: "block",
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "200px",
          lineHeight: "21px",
          color:
            data?.status === "new_report"
              ? "#006903"
              : data?.status === "under_manager_review"
              ? "#BC6809"
              : data?.status === "under_editor_final_review"
              ? "#105DB5"
              : "lightBlack",
        }}
      >
        {data?.status == "under_manager_review"
          ? "Under Review"
          : data?.status == "under_editor_final_review"
          ? "Final Review"
          : formatStringWithSpaces(data?.status)}
      </SoftTypography>
    ),
    Action: (
      <SoftBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={3}
        onClick={(e) => e.stopPropagation()}
      >
        <DeleteIcon
          sx={{
            cursor: "pointer",
            width: "24px",
            height: "24px",
            color: "error.main",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDialogue({
              type: "deleteInspection",
              isOpen: true,
              inspectionId: data._id,
              is_deleted: true,
            });
          }}
        />
        <Link to={`/update-inspection/${data?._id}`}>
          <EditIcon
            sx={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
              color: "white.main",
              backgroundColor: "primary.main",
              padding: "2px",
              borderRadius: "50%",
              marginTop: "8px",
            }}
          />
        </Link>
      </SoftBox>
    ),
  };
}

export default Rows;
