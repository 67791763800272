import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import { FaUser } from "react-icons/fa6";

export default function InspectorSignature() {
  const { store, setDialogue } = useStore();

  const {
    isOpen = false,
    inspectorName = "",
    signatureUrl = "",
  } = store?.app?.dialogs?.inspectorSignature || {};

  const handleClose = () =>
    setDialogue({ type: "inspectorSignature", isOpen: false });

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="signature-dialog-title"
      sx={{
        "& .MuiDialog-paper": {
          pb: 2,
          pt: 2,
          margin: 0,
          borderRadius: "12px",
          width: "540px",
        },
      }}>
      <DialogTitle id="signature-dialog-title" sx={{ pb: 1.5 }}>
        <SoftTypography
          variant="h5"
          color="lightBlack"
          fontWeight="bold"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            gap: 16,
            alignItems: "center",
          }}>
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
            backgroundColor="white.main"
            border="0.5px solid #02255B"
            sx={{
              height: "100px",
              width: "100px",
              overflow: "hidden",
            }}>
            <FaUser size={60} color="#C1C1C1" />
          </SoftBox>
          {`${inspectorName}'s Signature`}
        </SoftTypography>
      </DialogTitle>
      <DialogContent sx={{ px: 0, py: 0 }}>
        <SoftBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}>
          <SoftBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderRadius="15px"
            backgroundColor="shadow.main"
            sx={{
              height: "340px",
              width: "470px",
              overflow: "hidden",
            }}>
            {signatureUrl ? (
              <img
                src={signatureUrl}
                alt="Inspector Signature"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "15px",
                }}
              />
            ) : (
              <SoftTypography color="text" fontSize="16px">
                No signature available
              </SoftTypography>
            )}
          </SoftBox>
          
          <SoftTypography
            fontSize="16px"
            fontWeight="bold"
            align="center"
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={handleClose}>
            Close
          </SoftTypography>
        </SoftBox>
      </DialogContent>
    </Dialog>
  );
}
