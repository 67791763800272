import { useState, useEffect } from "react";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard React themes
import theme from "assets/theme";

// Soft UI Dashboard React routes
import routes from "routes";

// Soft UI Dashboard React contexts
import { useSoftUIController, setMiniSidenav } from "context";

import brand from "assets/images/logos/image.png";
import ApiResponse from "components/SoftDialog/ApiResponse";
import LogoutUser from "components/SoftDialog/LogoutUser";

function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, layout, sidenavColor } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));

    if (loginData?.isLoggedIn == true) {
      setIsAuthenticated(true);
      const role = loginData?.account_type?.toLowerCase();
      setUserRole(role);

      // Redirect based on role
      if (pathname === "/" || pathname === "/accounts") {
        if (role !== "admin") {
          navigate("/accounts");
        }
      } else if (
        pathname == "/authentication/sign-in" ||
        pathname == "/authentication/otp-verify" ||
        pathname == "/authentication/new-password" ||
        pathname == "/authentication/forgot-password"
      ) {
        if (role !== "admin") {
          navigate("/accounts");
        } else if (role == "admin") {
          navigate("/accounts");
        }
      }
    } else if (loginData?.isLoggedIn == false) {
      setIsAuthenticated(false);
      if (pathname == "/authentication/forgot-password") {
        navigate("/authentication/forgot-password");
      } else if (pathname == "/authentication/new-password") {
        navigate("/authentication/new-password");
      } else if (pathname == "/authentication/otp-verify") {
        navigate("/authentication/otp-verify");
      } else {
        navigate("/authentication/sign-in");
      }
    }
  }, [pathname, navigate]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes
      .map((route) => {
        if (route.shouldRender && !route.shouldRender()) {
          return null;
        }

        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      })
      .filter(Boolean);

  return (
    <ThemeProvider theme={theme}>
      <ApiResponse />
      <CssBaseline />
      {isAuthenticated ? (
        <>
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName=""
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            <Route
              path="*"
              element={
                <Navigate
                  to={userRole === "admin" ? "/accounts" : "/accounts"}
                  replace
                />
              }
            />
          </Routes>
        </>
      ) : (
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
        </Routes>
      )}

      <LogoutUser />
    </ThemeProvider>
  );
}

export default App;
