// @mui material components
import Card from "@mui/material/Card";
import React, { useState } from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useParams } from "react-router-dom";
import useStore from "store/hook";
import RemoveInspector from "components/SoftDialog/RemoveInspector";
import Table from "examples/Tables/Table";
import UserRow, { columns } from "store/action/InspectorOverview";
import headerBg from "assets/images/curved-images/Header.png";
import { FaUser } from "react-icons/fa6";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";
import InspectorSignature from "components/SoftDialog/InspectureSignature";
import signature from "assets/images/signature.png";
import { Link } from "react-router-dom";
import api from "store/api/api";
import { ReactComponent as EmptyFolderIcon } from "assets/images/small-logos/icon-emptyFolder.svg";

function InspectorOverview() {
  const { setDialogue } = useStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = React.useState("");
  const [rows, setRows] = useState([]);

  React.useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (!loginData) {
      navigate("/authentication/sign-in");
    }
    else if (searchData === "") {
      getProjects();
    }
  }, [navigate, searchData]);

  const getProjects = async (type = "all", searchData) => {
    try {
      setIsLoading(true);
      const response = await api.getProjectsList({
        type,
        page: 1,
        searchData: searchData,
      });
      if (response.status === 1) {
        setRows(response.data.map((project) => UserRow(project, setDialogue)));
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <RemoveInspector />
      <InspectorSignature />
      <SoftBox py={1}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={{ xs: 2, md: 3 }}
              py={2.5}>
              <SoftTypography color="white" variant="h6" fontSize="20px">
                <SoftTypography
                  component="span"
                  fontWeight="light"
                  variant="h6"
                  fontSize="20px">
                  <Link to="/reports" style={{ color: "white" }}>
                    Inspectors &gt;{" "}
                  </Link>
                </SoftTypography>
                Inspectors Overview
              </SoftTypography>
              <SoftTypography
                color="white"
                variant="h6"
                fontSize="20px"
                fontWeight="light">
                <SoftTypography
                  component="span"
                  fontWeight="bold"
                  variant="h6"
                  fontSize="20px">
                  Last Login{" "}
                </SoftTypography>
                - 10/31/2024 11:30 PM
              </SoftTypography>
            </SoftBox>

            <SoftBox sx={{ height: "calc(100vh - 190px)", overflowY: "auto" }}>
              <SoftBox
                px={{ xs: 2, md: 3 }}
                py={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <SoftBox display="flex" gap={2} alignItems="center">
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="8px"
                    backgroundColor="white.main"
                    border="0.5px solid #02255B"
                    sx={{
                      height: "190px",
                      width: "190px",
                      overflow: "hidden",
                    }}>
                    <FaUser size={120} color="#C1C1C1" />
                  </SoftBox>
                  <SoftBox display="flex" flexDirection="column" gap={1.5}>
                    <SoftTypography
                      display="flex"
                      alignItems="center"
                      gap={1}
                      fontSize="20px"
                      color="lightBlack"
                      fontWeight="bold"
                      mb={0.5}>
                      <PersonIcon
                        sx={{
                          color: "info.main",
                          width: "20px",
                          height: "20px",
                        }}
                      />{" "}
                      William Jackson
                    </SoftTypography>
                    <SoftTypography
                      display="flex"
                      alignItems="center"
                      gap={1}
                      fontSize="16px"
                      color="text">
                      <EmailIcon
                        sx={{
                          color: "info.main",
                          width: "20px",
                          height: "20px",
                        }}
                      />{" "}
                      john.doe@napworks.in
                    </SoftTypography>
                    <SoftBox
                      display="flex"
                      gap={1}
                      mt={0.5}
                      alignItems="center"
                      backgroundColor="input.main"
                      borderRadius="8px"
                      border="0.5px dashed #02255B"
                      padding="5px"
                      onClick={() =>
                        setDialogue({
                          type: "inspectorSignature",
                          isOpen: true,
                          inspectorName: "William Jackson",
                          signatureUrl: signature,
                        })
                      }
                      sx={{ cursor: "pointer" }}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="8px"
                        backgroundColor="white.main"
                        boxShadow="0 0 16px 2px rgba(0, 0, 0, 0.1)"
                        sx={{
                          height: "40px",
                          width: "50px",
                          overflow: "hidden",
                        }}>
                        {/* <FaUser size={15} color="#C1C1C1" /> */}
                        <img
                          src={signature}
                          alt="Inspector Signature"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                        />
                      </SoftBox>
                      <SoftTypography
                        fontSize="16px"
                        color="primary"
                        fontWeight="bold">
                        Inspector Signature
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>

                <SoftBox display="flex" flexDirection="column" gap={2}>
                  <SoftButton
                    variant="gradient"
                    color="primary"
                    sx={{
                      padding: "0px",
                      width: "195px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}>
                    Edit Inspector
                  </SoftButton>
                  <SoftButton
                    onClick={() =>
                      setDialogue({
                        type: "removeInspector",
                        isOpen: true,
                        userId: id,
                        is_deleted: true,
                      })
                    }
                    variant="gradient"
                    color="error"
                    sx={{
                      padding: "0px",
                      width: "195px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}>
                    Remove Inspector
                  </SoftButton>
                  <SoftTypography
                    onClick={() =>
                      navigate(`/inspectors/password-change/${id}`)
                    }
                    sx={{
                      textAlign: "center",
                      color: "lightBlack.main",
                      fontSize: "16px",
                      fontWeight: "bold",
                      border: "2px solid #212121",
                      padding: "6px 10px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "white.main",
                      },
                    }}>
                    Change Password
                  </SoftTypography>
                </SoftBox>
              </SoftBox>

              <SoftTypography mt={2} px={3} fontSize="24px" fontWeight="bold">
                Assigned Projects (08)
              </SoftTypography>

              <SoftBox
                sx={{
                  paddingTop: "20px",
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({
                        borders: { borderWidth, borderColor },
                      }) => `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}>
                {rows.length > 0 ? (
                  <Table columns={columns} rows={rows} />
                ) : (
                  <SoftBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      width="100%">
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        backgroundColor="shadow.main">
                        {columns.map(({ name, align, width }, index) => (
                          <SoftBox
                            key={index}
                            width={width}
                            align={align}
                            fontWeight="bold"
                            fontSize="16px"
                            py="14px"
                            px="24px">
                            {name}
                          </SoftBox>
                        ))}
                      </SoftBox>
                      <SoftTypography
                        fontSize="60px"
                        fontWeight="light"
                        height="70px"
                        color="fade">
                        0
                      </SoftTypography>
                      <SoftTypography
                        fontSize="25px"
                        fontWeight="medium"
                        color="fade"
                        pb={1.5}>
                        PROJECTS
                      </SoftTypography>
                      <EmptyFolderIcon
                        style={{
                          opacity: 0.8,
                        }}
                      />
                    </SoftBox>
                  </SoftBox>
                )}
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default InspectorOverview;
