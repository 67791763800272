import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import api from "store/api/api";
import { Collapse, DialogActions, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SoftInput from "components/SoftInput";
import CircularLoader from "./Loader";
import { ReactComponent as EmptyFolderIcon } from "assets/images/small-logos/icon-emptyFolder.svg";

export default function SelectDicipline({
  discipline,
  setDiscipline,
  newImages,
  setNewImages,
}) {
  const { store, setDialogue } = useStore();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedDiscipline, setSelectedDisciplie] = React.useState({
    comment: "",
    images: [],
    location: "",
    disciplineName: "",
    notes: "",
    inspectionType: "",
  });

  const [inspectiontype, SetInspectionType] = React.useState([]);
  const [comments, setComments] = React.useState([]);

  const [disciplineData, setdisciplineData] = React.useState([]);
  const [disciplineId, setdisciplineId] = React.useState([]);

  const [newlyAddedImages, setNewlyAddedImages] = React.useState([]);

  const { isOpen = false, selectedDicipline } =
    store?.app?.dialogs?.selectDicipline || {};
  const { isaddDicipline } = store?.app?.dialogs?.addDicipline || {};
  const { isaddLocation } = store?.app?.dialogs?.addLocation || {};
  const { isOpencomment, addComment, isAddcomment, predefinedcomments } =
    store?.app?.dialogs?.addComment || {};

  // Find the discipline matching selectedDiscipline.name and update location
  const selectedItem = disciplineData?.find(
    (item) => item?.disciplineName === selectedDiscipline?.disciplineName
  );

  React.useEffect(() => {
    if (discipline && discipline[selectedDicipline]) {
      setSelectedDisciplie({
        comment: discipline[selectedDicipline].comment || "",
        images: discipline[selectedDicipline].images || [],
        location: discipline[selectedDicipline].location || "",
        disciplineName: discipline[selectedDicipline].disciplineName || "",
        notes: discipline[selectedDicipline].notes || "",
        inspectionType: discipline[selectedDicipline].inspectionType || "",
      });
    }

    fetchInspectionDetails();

    selectedItem && SetInspectionType(selectedItem?.inspectionTypes);
  }, [isOpen, isaddDicipline, isaddLocation]);

  React.useEffect(() => {
    if (disciplineData.length > 0 && selectedDiscipline?.disciplineName) {
      const newItem = disciplineData.find(
        (item) => item?.name === selectedDiscipline?.disciplineName
      );
      SetInspectionType(newItem?.inspectionTypes || []);
      setdisciplineId(newItem?.discipline_id || "");
      setComments(newItem?.comments || []);
    }
  }, [disciplineData, selectedDiscipline, isaddLocation]);

  // React.useEffect(() => {
  //   setSelectedDisciplie((prev) => {
  //     if (prev.comment === addComment) return prev;
  //     return { ...prev, comment: addComment };
  //   });
  // }, [isAddcomment]);

  const handleClose = () => {
    setNewImages([]);
    setNewlyAddedImages([]);
    setDialogue({
      type: "selectDicipline",
      isOpen: false,
      selectedDicipline: "",
    });
  };

  const handleOpenAddDicipline = () => {
    setDialogue({
      type: "addDicipline",
      isOpen: true,
      isaddDicipline: true,
    });
  };

  const handleOpenAddLocation = () => {
    setDialogue({
      type: "addLocation",
      isOpen: true,
      disciplineId: disciplineId,
      isaddLocation: true,
    });
  };

  const handleOpenPredefinedComment = () => {
    setDialogue({
      type: "addComment",
      isOpencomment: true,
      addComment: selectedDiscipline?.comment || "",
      predefinedcomments: comments || [],
      isAddcomment: true,
    });
  };

  const fetchInspectionDetails = async () => {
    try {
      const response = await api.getDisciplineData();
      setdisciplineData(response?.data);
    } catch (error) {
      console.error("Error in fetching details", error);
    }
  };

  const ChangeData = () => {
    if (discipline.length !== 0) {
      setDiscipline(() =>
        discipline.map((item, i) =>
          i === selectedDicipline ? selectedDiscipline : item
        )
      );
    } else {
      setDiscipline((prev) => [...prev, selectedDiscipline]);
    }
  };

  const handleChangeInspectionType = (newInspectiontype) => {
    setSelectedDisciplie((prev) => ({
      ...prev,
      inspectionType: newInspectiontype,
    }));
  };

  const handleCangeDisciplie = (newdisciplie) => {
    setSelectedDisciplie((prev) => ({
      ...prev,
      disciplineName: newdisciplie?.name,
      inspectionType: "",
    }));
  };

  const handleUpdateDiscipline = () => {
    setLoading(true);
    setNewImages(newlyAddedImages);
    ChangeData();
    setDialogue({
      type: "selectDicipline",
      isOpen: false,
      selectedDicipline: "",
    });
    setLoading(false);
  };

  const handleRemoveImage = (imageToRemove) => {
    setSelectedDisciplie((prev) => ({
      ...prev,
      images: prev.images.filter((img) => img !== imageToRemove),
    }));
  };

  const handleRemoveNewImage = (imageToRemove) => {
    // setNewImages((prev) => ([
    //     prev.filter((img) => img !== imageToRemove),
    // ]));

    setNewlyAddedImages((prevImages) =>
      prevImages.filter((_, i) => i !== imageToRemove)
    );
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;

    if (files) {
      setNewlyAddedImages((prev) => [...prev, ...files]);
    }
  };

  const [comment, setComment] = React.useState("");

  React.useEffect(() => {
    setComment(addComment);
  }, [addComment]);

  const handleCloseComment = () =>
    setDialogue({
      type: "addComment",
      isOpencomment: false,
      addComment: comment,
      isAddcomment: false,
    });


  const handleAppendComment = (comment) => {
    setSelectedDisciplie((prev) => {
      return { ...prev, comment: prev.comment + " " + comment };
    });
    handleCloseComment();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="md"
        fullWidth
        aria-labelledby="select-inspector-dialog"
        sx={{
          "& .MuiDialog-paper": {
            margin: 2,
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: "white.main",
            padding: "18px 24px !important",
          }}
        >
          <SoftBox display="flex" alignItems="center" justifyContent="center">
            <SoftTypography
              color="lightBlack"
              fontSize="26px"
              fontWeight="bold"
              sx={{ color: "#212121" }}
            >
              SELECT DISCIPLINE
            </SoftTypography>
          </SoftBox>

          <SoftBox display="flex" flexDirection="column" gap={2}>
            <SoftBox>
              <SoftBox
                sx={{
                  backgroundColor: "white.main",
                  overflowY: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SoftTypography
                  color="lightBlack"
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ color: "#212121" }}
                >
                  Select Discipline :
                </SoftTypography>

                <SoftTypography
                  color="lightBlack"
                  fontSize="14px"
                  fontWeight="regular"
                  sx={{
                    color: " #737373",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenAddDicipline}
                >
                  Cant Find Discipline?
                </SoftTypography>
              </SoftBox>

              <SoftBox
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                {disciplineData &&
                  disciplineData.map((item, index) => (
                    <SoftTypography
                      key={index}
                      fontSize="16px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        borderRadius: "12px",
                        padding: "6px 30px !important",
                        cursor: "pointer",
                        backgroundColor:
                          item?.name === selectedDiscipline?.disciplineName
                            ? "#DBF6E7"
                            : "#F1F4F8",
                        boxShadow:
                          item?.name === selectedDiscipline?.disciplineName
                            ? "0px 4px 4px 0px #00000040"
                            : "none",
                      }}
                      my={1}
                      onClick={() => handleCangeDisciplie(item)}
                    >
                      {item?.name}
                    </SoftTypography>
                  ))}
              </SoftBox>
            </SoftBox>

            <SoftBox>
              <SoftBox
                sx={{
                  backgroundColor: "white.main",
                  overflowY: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SoftTypography
                  color="lightBlack"
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ color: "#212121" }}
                >
                  Inspection Type :
                </SoftTypography>

                <SoftTypography
                  fontSize="14px"
                  fontWeight="regular"
                  sx={{
                    color: "#737373",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenAddLocation}
                >
                  Can't Find Inspection type?
                </SoftTypography>
              </SoftBox>

              <Box>
                <SoftBox
                  sx={{
                    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "12px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  {/* Dropdown Header */}
                  <Box
                    sx={{
                      backgroundColor: "#F1F4F8",
                      borderRadius: "12px",
                      padding: "0px 15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpen(!open)} // Toggle dropdown on click
                  >
                    <Typography fontSize="16px">
                      {selectedDiscipline?.inspectionType ||
                        "Select Inspection Type"}
                    </Typography>
                    <IconButton
                      onClick={() => setOpen(!open)}
                      sx={{
                        transform: open ? "rotate(180deg)" : "rotate(0deg)", // Rotate icon when open
                        transition: "0.3s ease-in-out",
                      }}
                    >
                      <ExpandMoreIcon
                        sx={{ color: "#000000", width: 28, height: 28 }}
                      />
                    </IconButton>
                  </Box>

                  {/* Dropdown Content */}
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box
                      sx={{
                        backgroundColor: "#FFFFFF",
                        padding: "10px",
                        borderRadius: "0 0 12px 12px",
                      }}
                    >
                      {inspectiontype?.length > 0 ? (
                        inspectiontype.map((item, index) => (
                          <SoftTypography
                            fontSize="14px"
                            color="primary"
                            key={index}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              handleChangeInspectionType(item?.name);
                              setOpen(!open);
                            }}
                          >
                            {item?.name}
                          </SoftTypography>
                        ))
                      ) : (
                        <SoftBox
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          gap={1}
                        >
                          <SoftTypography
                            fontSize="40px"
                            fontWeight="light"
                            height="50px"
                            color="fade"
                          >
                            0
                          </SoftTypography>
                          <SoftTypography
                            fontSize="20px"
                            fontWeight="medium"
                            color="fade"
                          >
                            Inspection Type
                          </SoftTypography>
                          <EmptyFolderIcon
                            style={{
                              opacity: 0.8,
                              width: "100px",
                              height: "100px",
                            }}
                          />
                        </SoftBox>
                      )}
                    </Box>
                  </Collapse>
                </SoftBox>
              </Box>
            </SoftBox>

            <SoftBox>
              <SoftBox
                sx={{
                  backgroundColor: "white.main",
                  overflowY: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SoftTypography
                  color="lightBlack"
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ color: "#212121" }}
                >
                  Location :
                </SoftTypography>
              </SoftBox>

              <SoftBox>
                <SoftInput
                  name="comment"
                  value={selectedDiscipline?.location}
                  onChange={(e) =>
                    setSelectedDisciplie((prev) => ({
                      ...prev,
                      location: e?.target?.value,
                    }))
                  }
                  placeholder="Enter Location"
                  multiline
                  sx={{
                    padding: "14px 12px",
                    "& .MuiInputBase-input::placeholder": {
                      color: "#212121 !important",
                      opacity: 1,
                      fontSize: "16px",
                    },
                  }}
                />
              </SoftBox>
            </SoftBox>

            <SoftBox>
              <SoftBox
                sx={{
                  backgroundColor: "white.main",
                  overflowY: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SoftTypography
                  color="lightBlack"
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ color: "#212121" }}
                >
                  Comment :
                </SoftTypography>

                <SoftTypography
                  color="lightBlack"
                  fontSize="14px"
                  fontWeight="regular"
                  sx={{
                    color: " #737373",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenPredefinedComment}
                >
                  Pre-Defined Comments
                </SoftTypography>
              </SoftBox>

              <SoftBox>
                <SoftInput
                  name="comment"
                  value={selectedDiscipline?.comment}
                  onChange={(e) =>
                    setSelectedDisciplie((prev) => ({
                      ...prev,
                      comment: e?.target?.value,
                    }))
                  }
                  placeholder="Add Comments"
                  multiline
                  rows={4}
                  sx={{
                    padding: "14px 12px",
                    "& .MuiInputBase-input::placeholder": {
                      color: "#212121 !important",
                      opacity: 1,
                      fontSize: "16px",
                    },
                  }}
                />
              </SoftBox>
            </SoftBox>

            <SoftBox>
              <SoftBox
                sx={{
                  backgroundColor: "white.main",
                  overflowY: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SoftTypography
                  color="lightBlack"
                  fontSize="18px"
                  fontWeight="bold"
                  sx={{ color: "#212121" }}
                >
                  Notes :
                </SoftTypography>
              </SoftBox>

              <SoftBox>
                <SoftInput
                  name="notes"
                  value={selectedDiscipline?.notes}
                  onChange={(e) =>
                    setSelectedDisciplie((prev) => ({
                      ...prev,
                      notes: e?.target?.value,
                    }))
                  }
                  placeholder="Add Internal Notes"
                  multiline
                  rows={4}
                  sx={{
                    padding: "14px 12px",
                    "& .MuiInputBase-input::placeholder": {
                      color: "#212121 !important",
                      opacity: 1,
                      fontSize: "16px",
                    },
                  }}
                />
              </SoftBox>
            </SoftBox>

            <SoftBox display="flex" alignItems="center" gap={2}>
              <SoftButton
                variant="contained"
                component="label"
                sx={{
                  outlined: "none",
                  backgroundColor: "#F1F4F8",
                  width: 80,
                  height: 80,
                  color: "#02255B",
                  borderRadius: "12px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                onChange={handleImageUpload}
              >
                Choose Photo
                <input type="file" hidden multiple accept="image/*" />
              </SoftButton>

              <SoftBox
                display="flex"
                alignItems="center"
                gap={1}
                flexWrap="wrap"
              >
                {selectedDiscipline?.images.map((image, index) => (
                  <SoftBox position="relative" mt={1}>
                    {/* Remove Button */}
                    <IconButton
                      sx={{
                        position: "absolute",
                        width: 20,
                        height: 20,
                        padding: "5px",
                        backgroundColor: "#f8f8f8",
                        border: "0.63px solid #02255B",
                        boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                        "&:hover": { backgroundColor: "#f8f8f8" },
                      }}
                      onClick={() => handleRemoveImage(image)}
                    >
                      <CloseIcon
                        fontSize="5px"
                        sx={{ color: "#02255B", fontSize: 18 }}
                      />
                    </IconButton>

                    <SoftBox
                      key={index}
                      component="img"
                      src={image}
                      sx={{
                        width: 80,
                        height: 80,
                        objectFit: "cover",
                        borderRadius: "12px",
                        border: "0.63px solid #02255B",
                      }}
                    />
                  </SoftBox>
                ))}
              </SoftBox>

              <SoftBox
                display="flex"
                alignItems="center"
                gap={1}
                flexWrap="wrap"
              >
                {newlyAddedImages?.map((image, index) => (
                  <SoftBox position="relative" mt={1}>
                    {/* Remove Button */}
                    <IconButton
                      sx={{
                        position: "absolute",
                        width: 20,
                        height: 20,
                        padding: "5px",
                        backgroundColor: "#f8f8f8",
                        border: "0.63px solid #02255B",
                        boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                        "&:hover": { backgroundColor: "#f8f8f8" },
                      }}
                      onClick={() => handleRemoveNewImage(index)}
                    >
                      <CloseIcon
                        fontSize="5px"
                        sx={{ color: "#02255B", fontSize: 18 }}
                      />
                    </IconButton>

                    <SoftBox
                      key={index}
                      component="img"
                      src={URL.createObjectURL(image)}
                      sx={{
                        width: 80,
                        height: 80,
                        objectFit: "cover",
                        borderRadius: "12px",
                        border: "0.63px solid #02255B",
                      }}
                    />
                  </SoftBox>
                ))}
              </SoftBox>
            </SoftBox>

            <DialogActions
              sx={{
                justifyContent: "center",
                flexDirection: "column",
                gap: 3,
                padding: "0px",
              }}
            >
              <SoftButton
                variant="gradient"
                color="primary"
                sx={{
                  width: "45%",
                  padding: "14px 24px",
                  fontSize: "16px",
                }}
                onClick={handleUpdateDiscipline}
              >
                {loading == true ? <CircularLoader type="small" /> : "Add"}
              </SoftButton>
              <SoftTypography
                fontSize="16px"
                fontWeight="bold"
                align="center"
                color="primary"
                sx={{ margin: "0px !important", cursor: "pointer" }}
                onClick={handleClose}
              >
                Cancel
              </SoftTypography>
            </DialogActions>
          </SoftBox>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isOpencomment}
        maxWidth="md"
        fullWidth
        aria-labelledby="select-inspector-dialog"
        sx={{
          "& .MuiDialog-paper": {
            margin: 2,
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: "white.main",
            padding: "18px 24px !important",
          }}
        >
          <SoftBox display="flex" flexDirection="column" gap={2}>
            <SoftBox
              mt={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
            >
              <SoftTypography
                fontSize="26px"
                fontWeight="bold"
                sx={{ color: "#212121" }}
              >
                PREDEFINED COMMENTS
              </SoftTypography>

              <SoftTypography
                fontSize="14px"
                fontWeight="400"
                sx={{
                  color: "#737373",
                  maxWidth: "70%",
                }}
              >
                Click on any option to edit or include in the comments.{" "}
              </SoftTypography>
            </SoftBox>

            <SoftBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
              gap={1.5}
            >
              {predefinedcomments?.length > 0 ? (
                predefinedcomments?.map((comment, index) => (
                  <SoftTypography
                    fontSize="14px"
                    fontWeight="400"
                    color="primary"
                    sx={{
                      maxWidth: "90%",
                      cursor: "pointer",
                    }}
                    onClick={() => handleAppendComment(comment?.comment)}
                  >
                    {comment?.comment}
                  </SoftTypography>
                ))
              ) : (
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={1}
                >
                  <SoftTypography
                    fontSize="40px"
                    fontWeight="light"
                    height="50px"
                    color="fade"
                  >
                    0
                  </SoftTypography>
                  <SoftTypography
                    fontSize="20px"
                    fontWeight="medium"
                    color="fade"
                  >
                    predefiend comments
                  </SoftTypography>
                  <EmptyFolderIcon
                    style={{
                      opacity: 0.8,
                      width: "100px",
                      height: "100px",
                    }}
                  />
                </SoftBox>
              )}
            </SoftBox>

            <DialogActions
              sx={{
                justifyContent: "center",
                flexDirection: "column",
                gap: 3,
                padding: "0px",
              }}
            >
              <SoftTypography
                fontSize="16px"
                fontWeight="bold"
                align="center"
                color="primary"
                sx={{ margin: "0px !important", cursor: "pointer" }}
                onClick={handleCloseComment}
              >
                Cancel
              </SoftTypography>
            </DialogActions>
          </SoftBox>
        </DialogContent>
      </Dialog>  
    </>
  );
}
