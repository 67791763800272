import * as React from "react";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import EmailIcon from "@mui/icons-material/Email";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/logos/image.png";
import { useNavigate } from "react-router-dom";

import api from "store/api/api";

function ForgotPassword() {
  const navigate = useNavigate();
  const [form, setForm] = React.useState({ email: "", otp_mode: "development" });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleChange = (e) =>
    setForm((data) => ({ ...data, [e.target.name]: e.target.value }));

  const submitForm = async () => {
    if (!form.email || !/\S+@\S+\.\S+/.test(form.email)) {
      setError("Please enter a valid email address");
      return;
    }

    setIsSubmitting(true);
    setError("");

    try {
      await api.sendForgotOtp(form);
      localStorage.setItem("resetEmail", form.email);
      navigate("/authentication/otp-verify");
    } catch (err) {
      setError("Something went wrong. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CoverLayout
      title="Forgot Password"
      color="primary"
      description="Don't worry! It occurs. Please enter the email address linked with your account."
      image={curved9}>
      <SoftBox component="form" role="form" width="100%">
        <SoftBox mb={3}>
          <SoftInput
            value={form.email}
            name="email"
            onChange={handleChange}
            type="email"
            placeholder="Email Address"
            sx={{
              padding: "16px 12px 16px 4px",
              "&.Mui-focused": {
                padding: "16px 42px 16px 4px",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#737373 !important",
                fontSize: "16px",
                opacity: 1,
              },
              "& .MuiInputBase-input": {
                fontSize: "16px",
              },
            }}
            icon={{ component: <EmailIcon />, direction: "left" }}
          />
        </SoftBox>

        <SoftBox mt={5} mb={1} width="75%">
          <SoftButton
            onClick={submitForm}
            variant="gradient"
            color="primary"
            sx={{
              fontSize: "16px",
              padding: "14px 24px",
              fontWeight: "bold",
            }}
            fullWidth
            disabled={isSubmitting}>
            {isSubmitting ? "Sending..." : "Send OTP"}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default ForgotPassword;
