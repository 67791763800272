// @mui material components
import Card from "@mui/material/Card";
import SearchIcon from "@mui/icons-material/Search";
import { Checkbox, Menu, MenuItem } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";
import SoftPagination from "components/SoftPagination";
import UserRow, { columns } from "store/action/User";
import React, { useState } from "react";
import DisableUserConfirm from "components/SoftDialog";
import DeleteUser from "components/SoftDialog/DeleteUser";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import api from "store/api/api";
import { ReactComponent as EmptyFolderIcon } from "assets/images/small-logos/icon-emptyFolder.svg";

// Data
import SoftInput from "components/SoftInput";
import useStore from "store/hook";
import headerBg from "assets/images/curved-images/Header.png";
import { Label } from "@mui/icons-material";

const ACCOUNT_TYPES = {
  Editor: "editor",
  "Project Manager": "project_manager",
  Inspector: "inspector",
};

function Accounts() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [role, setRole] = React.useState("All");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = React.useState("");
  const open = Boolean(anchorEl);
  const { setDialogue } = useStore();
  const [userData, setUserData] = useState({});
  const [rows, setRows] = useState([]);
  const [checked, setChecked] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [deletedAccount, setDeletedAccount] = useState("");

  const handleSearch = () => {
    getUsersList("search", searchData, "");
  };

  React.useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (!loginData) {
      navigate("/authentication/sign-in");
    } else if (searchData === "" && accountType?.length <= 0) {
      getUsersList();
    } else if (searchData !== "" && accountType?.length <= 0) {
      setPage(1);
      getUsersList("all", searchData, "");
    } else {
      setPage(1);
    }
  }, [navigate, searchData, page]);

  const getUsersList = async (
    type = "all",
    searchData,
    accountType,
    is_deleted_needed = ""
  ) => {
    searchData?.length > 0
      ? (type = "search")
      : accountType?.length > 0
      ? (type = "filter")
      : (type = "all");

    try {
      setIsLoading(true);
      const response = await api.getUsersList({
        page: page,
        type: type,
        searchData: searchData,
        accountType: accountType,
        is_deleted_needed: is_deleted_needed,
      });
      if (response.status === 1) {
        setRows(
          response.data.users.map((user) =>
            UserRow(user, setDialogue, () =>
              getUsersList(type, searchData, accountType)
            )
          )
        );
        setUserData(response.data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value) => {
    const apiValue = ACCOUNT_TYPES[value];
    setAccountType(apiValue);
    const isdelted = checked ? "yes" : "";
    setRole(value);
    setAnchorEl(null);

    if (value === "All") {
      getUsersList();
    } else {
      getUsersList("filter", "", apiValue, isdelted);
    }
  };

  const handleChange = () => {
   
    if (checked) {
      setDeletedAccount("yes");
      getUsersList("filter", "", accountType, "");
    } else {
      getUsersList("", "", accountType, "yes");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DisableUserConfirm />
      <DeleteUser />
      <SoftBox pt={1} mb={2.5}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "sticky",
                top: 0,
                zIndex: 2,
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={3}
              py={2}
            >
              <SoftTypography
                color="white"
                variant="h6"
                fontSize="20px"
                fontWeight="light"
              >
                Total Accounts -{" "}
                <SoftTypography component="span" variant="h6" fontSize="20px">
                  {userData.totalUsers || 0}
                </SoftTypography>
              </SoftTypography>
              <SoftBox display="flex" gap={2}>
                <SoftBox
                  onClick={() => {
                    navigate("/accounts/add-account");
                  }}
                  sx={{
                    cursor: "pointer",
                    border: "2px solid white",
                    borderRadius: "6px",
                    padding: "0px 42px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SoftTypography variant="h6" color="white">
                    Add Account
                  </SoftTypography>
                </SoftBox>

                <SoftBox sx={{ width: "280px", borderRadius: "6px" }}>
                  <SoftInput
                    value={searchData}
                    onChange={(e) => setSearchData(e.target.value)}
                    placeholder="Search..."
                    icon={{
                      component: (
                        <SearchIcon
                          onClick={searchData.trim() ? handleSearch : undefined}
                          sx={{
                            cursor: searchData.trim() ? "pointer" : "default",
                          }}
                        />
                      ),
                      direction: "left",
                    }}
                    sx={{
                      padding: "10px 12px 10px 4px",
                      "& .MuiInputBase-input": {
                        fontSize: "16px",
                        fontWeight: "regular",
                      },
                    }}
                  />
                </SoftBox>

                <SoftBox mb={0} sx={{ position: "relative" }}>
                  <SoftBox
                    onClick={handleClick}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "7px 12px",
                      borderRadius: "6px",
                      cursor: "pointer",
                      width: "140px",
                      backgroundColor: "white.main",
                    }}
                  >
                    <SoftTypography
                      fontSize="16px"
                      sx={{
                        color: "lightBlack.main",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100px",
                      }}
                    >
                      {role}
                    </SoftTypography>
                    <IoIosArrowDown
                      size={20}
                      style={{
                        marginLeft: "auto",
                        color: "primary.main",
                      }}
                    />
                  </SoftBox>

                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    PaperProps={{
                      sx: {
                        mt: 1.9,
                        border: "1px solid black",
                        borderBottom: "none",
                        padding: 0,
                        borderRadius: 0,
                        width: "270px",
                        boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
                      },
                    }}
                  >
                    {["All", "Editor", "Project Manager", "Inspector"].map(
                      (option) => (
                        <MenuItem
                          key={option}
                          onClick={() => {
                            handleMenuItemClick(option);
                          }}
                          sx={{
                            borderRadius: 0,
                            padding: "14px 12px",
                            borderBottom: "1px solid black",
                            fontSize: "14px",
                            color: "primary.main",
                            "&:hover": {
                              backgroundColor: "none !important",
                            },
                          }}
                        >
                          {option}
                        </MenuItem>
                      )
                    )}
                  </Menu>
                </SoftBox>

                <SoftBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 12px",
                    borderRadius: "6px",
                    cursor: "pointer",
                    backgroundColor: "white.main",
                  }}
                >
                  <SoftBox position="relative" mr={0.5}>
                    <Checkbox
                      {...Label}
                      checked={checked}
                      onClick={() => {
                        handleChange();
                        setChecked((prev) => !prev);
                      }}
                    />
                  </SoftBox>
                  <SoftTypography
                    fontSize="16px"
                    sx={{
                      color: "lightBlack.main",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Deleted Account
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox
              sx={{
                position: "relative",
                "& .MuiTableContainer-root": {
                  maxHeight: rows.length > 0 ? "calc(100vh - 245px)" : "auto",
                  overflow: rows.length > 0 ? "auto" : "visible",
                },
                "& .MuiTableHead-root": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "white",
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {isLoading ? (
                <SoftBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="calc(100vh - 180px)"
                  p={3}
                >
                  <SoftTypography variant="subtitle1" color="textSecondary">
                    Loading...
                  </SoftTypography>
                </SoftBox>
              ) : rows.length > 0 ? (
                <Table columns={columns} rows={rows} />
              ) : (
                <SoftBox
                  sx={{
                    height: "calc(100vh - 180px)",
                    overflowY: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                  }}
                >
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    gap={8}
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      backgroundColor="shadow.main"
                    >
                      {columns.map(({ name, align, width }, index) => (
                        <SoftBox
                          key={index}
                          width={width}
                          align={align}
                          fontWeight="bold"
                          fontSize="18px"
                          py="14px"
                          px="24px"
                        >
                          {name}
                        </SoftBox>
                      ))}
                    </SoftBox>

                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <SoftTypography
                        fontSize="90px"
                        fontWeight="light"
                        height="110px"
                        color="fade"
                      >
                        0
                      </SoftTypography>
                      <SoftTypography
                        fontSize="40px"
                        fontWeight="medium"
                        color="fade"
                        pb={2}
                      >
                        ACCOUNTS
                      </SoftTypography>
                      <EmptyFolderIcon style={{ opacity: 0.8 }} />
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              )}
            </SoftBox>

            {rows.length > 0 && !isLoading && (
              <SoftBox
                sx={{
                  backgroundColor: "#ffffff",
                  position: "sticky",
                  bottom: 0,
                  zIndex: 2,
                }}
              >
                <SoftPagination
                  Totalpages={userData.totalPages}
                  currentPage={userData.currentPage}
                  changePage={setPage}
                />
              </SoftBox>
            )}
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Accounts;
