const getTimeFromMilliseconds = (seconds) => {

  const  milliseconds = new Date(seconds * 1000);
    
  const date = new Date(milliseconds);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, 
    });
  }

export default getTimeFromMilliseconds;