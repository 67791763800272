import { Card } from "@mui/material";
import headerBg from "assets/images/curved-images/Header.png";
import SoftBox from "components/SoftBox";
import CircularLoader from "components/SoftDialog/Loader";
import SoftTypography from "components/SoftTypography";
import React, { useState } from "react";
import { ReactComponent as EmptyFolderIcon } from "assets/images/small-logos/icon-emptyFolder.svg";
import useStore from "store/hook";
import Table from "examples/Tables/Table";
import SoftAvatar from "components/SoftAvatar";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Addappdata from "components/SoftDialog/Addappdata";
import DeleteAppdata from "components/SoftDialog/DeleteAppdata";
import formatStringWithSpaces from "services/stringconverter";
import convertMillisecondsToDate from "services/dateconverter";

export const columns = [
  { name: "Inspection Type", align: "left" },
  { name: "Type", align: "left" },
  { name: "Added By", align: "left" },
  { name: "Created At", align: "left" },
  { name: "Action", align: "left" },
];

function Author({name, picture}) {
  const data = localStorage?.getItem("loginData");
  const adminData = JSON.parse(data);
  return (
    <SoftBox display="flex" alignItems="center" justifyContent="center">
      <SoftBox mr={2}>
        <SoftAvatar
          src= {picture || adminData?.profile_picture|| "https://i.pinimg.com/736x/07/33/ba/0733ba760b29378474dea0fdbcb97107.jpg"}
          alt="image"
          size="sm"
          variant="rounded"
        />
      </SoftBox>

      <SoftBox display="flex" flexDirection="column" gap={0.8}>
        <SoftTypography
          variant="button"
          fontWeight="medium"
          color="lightBlack"
          fontSize="14px"
          textTransform="none"
        >
          {name || adminData?.fullName}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Location({ inspectionTypes, disciplineId }) {
  const [rows, setRows] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setDialogue, store } = useStore();


  React.useEffect(() => {
    setIsLoading(true);

    if (inspectionTypes && inspectionTypes.length > 0) {
      const mappedRows = inspectionTypes.map((inspectionType) => {
        return {
          "Inspection Type": (
            <SoftTypography
              variant="text"
              color="lightBlack"
              fontWeight="medium"
              textTransform="capitalize"
              fontSize="14px"
              padding="0px 0px 0px 14px !important"
              sx={{
                display: "block",
                whiteSpace: "normal",
                wordWrap: "break-word",
                lineHeight: "1.5em",
              }}
            >
              {inspectionType?.name}
            </SoftTypography>
          ),
          Type: (
            <SoftTypography
              variant="text"
              color="lightBlack"
              fontWeight="medium"
              fontSize="14px"
              textTransform="capitalize"
            >
              {formatStringWithSpaces(inspectionType?.type)}
            </SoftTypography>
          ),
          "Created At": (
            <SoftTypography
              variant="text"
              color="lightBlack"
              fontWeight="regular"
              fontSize="14px"
              textTransform="capitalize"
              display="inline-flex"
              alignItems="center"
            >
            {convertMillisecondsToDate(inspectionType?.createdTime)}
            </SoftTypography>
          ),

          "Added By": (
            <SoftBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={1}
            >
              <Author 
              name={inspectionType?.added_by_name}
              picture={inspectionType?.added_by_profile_picture}
              />
            </SoftBox>
          ),
          Action: (
            <SoftBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              ml={1.5}
              gap={3}
            >
              <DeleteIcon
                sx={{
                  cursor: "pointer",
                  width: "24px",
                  height: "24px",
                  color: "error.main",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDialogue({
                    type: "deleteAppdata",
                    isOpen: true,
                    disciplinId: disciplineId,
                    inspectionTypeId: inspectionType?.inspectionTypeId,
                    commentId: '',
                    is_deleted: true,
                    action: "inspectiontype",
                  });
                }}
              />
              <EditIcon
                sx={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                  color: "white.main",
                  backgroundColor: "primary.main",
                  padding: "2px",
                  borderRadius: "50%",
                  marginTop: "2px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDialogue({
                    type: "addAppdata",
                    isOpen: true,
                    disciplineId: disciplineId,
                    inspectionTypeId: inspectionType?.inspectionTypeId,
                    commentId: '',
                    is_deleted: true,
                    action: "inspectiontype",
                    text: inspectionType?.name,
                    isEdit: true,
                  });
                }}
              />
            </SoftBox>
          ),
        };
      });

      setRows(mappedRows);
    }
    setIsLoading(false);
  }, [inspectionTypes]);

  return (
    <SoftBox pt={1} mb={2.5} pl={5} pr={2}>
      <DeleteAppdata />
      <Addappdata />
      <SoftBox mb={3}>
        <Card>
          <SoftBox
            sx={{
              backgroundColor: "primary.main",
              backgroundImage: `url(${headerBg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "sticky",
              top: 0,
            }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={3}
            py={1}
          >
            <SoftTypography
              color="white"
              variant="h6"
              fontSize="15px"
              fontWeight="light"
              textTransform="uppercase"
            >
              Inspection Type
            </SoftTypography>

            <SoftBox
              sx={{
                cursor: "pointer",
                border: "2px solid white",
                borderRadius: "6px",
                padding: "4px 35px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SoftTypography
                variant="h6"
                color="white"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDialogue({
                    type: "addAppdata",
                    isOpen: true,
                    disciplineId: disciplineId,
                    action: "inspectiontype",
                  });
                }}
              >
                Add Inspection Type
              </SoftTypography>
            </SoftBox>
          </SoftBox>

          <SoftBox
            sx={{
              position: "relative",
              "& .MuiTableContainer-root": {
                maxHeight: rows?.length > 0 ? "calc(100vh - 245px)" : "auto",
                overflow: rows?.length > 0 ? "auto" : "visible",
              },
              "& .MuiTableHead-root": {
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            {isLoading ? (
              <SoftBox
                display="flex"
                height="calc(100vh - 180px)"
                justifyContent="center"
                alignItems="center"
                p={3}
              >
                <SoftTypography variant="subtitle1" color="textSecondary">
                  <CircularLoader type="large" />
                </SoftTypography>
              </SoftBox>
            ) : !isLoading && rows?.length > 0 ? (
              <Table columns={columns} rows={rows} />
            ) : (
              <SoftBox
                sx={{
                  height: "calc(35vh)",
                  overflowY: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                }}
              >
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  gap={3}
                >
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    backgroundColor="shadow.main"
                  >
                    {columns.map(({ name, align, width }, index) => (
                      <SoftBox
                        key={index}
                        width={width}
                        align={align}
                        fontWeight="bold"
                        fontSize="18px"
                        py="14px"
                        px="24px"
                      >
                        {name}
                      </SoftBox>
                    ))}
                  </SoftBox>

                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={1}
                  >
                    <SoftTypography
                      fontSize="40px"
                      fontWeight="light"
                      height="50px"
                      color="fade"
                    >
                      0
                    </SoftTypography>
                    <SoftTypography
                      fontSize="20px"
                      fontWeight="medium"
                      color="fade"
                    >
                      LOCATION
                    </SoftTypography>
                    <EmptyFolderIcon style={{ opacity: 0.8, width: "100px", height: "100px" }} />
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            )}
          </SoftBox>
        </Card>
      </SoftBox>
    </SoftBox>
  );
}

export default Location;
