const getDateFromSeconds = (seconds) => {
    const date = new Date(seconds * 1000);
  
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  };
  
  export default getDateFromSeconds;
  