// @mui material components
import Card from "@mui/material/Card";
import React from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import LockIcon from "@mui/icons-material/Lock";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import headerBg from "assets/images/curved-images/Header.png";
import { toast } from "react-toastify";
import api from "store/api/api";

function Settings() {
  const navigate = useNavigate();

  const [form, setForm] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("password");

  React.useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (!loginData) {
      navigate("/authentication/sign-in");
    }
  }, [navigate]);

  const handleChange = (e) =>
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const toggleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const toggleCurrentPasswordVisibility = () =>
    setShowCurrentPassword(!showCurrentPassword);

  const validateForm = () => {
    const requiredFields = {
      "Current Password": form.currentPassword,
      "New Password": form.newPassword,
      "Confirm Password": form.confirmPassword,
    };

    const emptyField = Object.entries(requiredFields).find(
      ([_, value]) => !value
    );
    if (emptyField) {
      toast.error(`${emptyField[0]} is required`);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      setIsSubmitting(true);
      if (form.newPassword === form.confirmPassword) {
        const data = {
          password: form.currentPassword,
          new_password: form.newPassword,
        };
        const response = await api.changePassword(data);
        if (response.status === 1) {
          toast.success(response.message);
          setForm({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        } else {
          toast.error(response.message);
        }
      } else {
        toast.error("New password and confirm password do not match");
      }
    } catch (error) {
      console.error("Failed to change password:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={1}>
        <SoftBox mb={3}>
          <Card sx={{ minHeight: "calc(100vh - 110px)" }}>
            <SoftBox
              sx={{
                backgroundColor: "primary.main",
                backgroundImage: `url(${headerBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "sticky",
                top: 0,
                zIndex: 2,
              }}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={{ xs: 2, md: 3 }}
              py={2.5}>
              <SoftTypography color="white" variant="h6" fontSize="20px">
                Settings
              </SoftTypography>
            </SoftBox>
            <SoftBox pl={3} pr={4} py={2} display="flex" flexDirection="column">
              <SoftBox mb={2} display="flex" gap={2}>
                <SoftTypography
                  variant="h6"
                  sx={{
                    padding: "4px 12px",
                    fontSize: "16px",
                    width: "200px",
                    textAlign: "center",
                    alignSelf: "center",
                    borderRadius: "20px",
                    border: "0.5px solid",
                    borderColor: "border.main",
                    cursor: "pointer",
                    fontWeight: activeTab === "password" ? "bold" : "regular",
                    backgroundColor:
                      activeTab === "password" ? "info.main" : "white.main",
                    color:
                      activeTab === "password" ? "white.main" : "text.main",
                  }}
                  onClick={() => setActiveTab("password")}>
                  Change Password
                </SoftTypography>

                <SoftTypography
                  variant="h6"
                  sx={{
                    padding: "4px 12px",
                    fontSize: "16px",
                    width: "200px",
                    textAlign: "center",
                    alignSelf: "center",
                    borderRadius: "20px",
                    border: "0.5px solid",
                    borderColor: "border.main",
                    cursor: "pointer",
                    fontWeight: activeTab === "privacy" ? "bold" : "regular",
                    backgroundColor:
                      activeTab === "privacy" ? "info.main" : "white.main",
                    color: activeTab === "privacy" ? "white.main" : "text.main",
                  }}
                  onClick={() => setActiveTab("privacy")}>
                  Privacy Policy
                </SoftTypography>
                <SoftTypography
                  variant="h6"
                  sx={{
                    padding: "4px 12px",
                    fontSize: "16px",
                    width: "200px",
                    textAlign: "center",
                    alignSelf: "center",
                    borderRadius: "20px",
                    border: "0.5px solid",
                    borderColor: "border.main",
                    cursor: "pointer",
                    fontWeight: activeTab === "terms" ? "bold" : "regular",
                    backgroundColor:
                      activeTab === "terms" ? "info.main" : "white.main",
                    color: activeTab === "terms" ? "white.main" : "text.main",
                  }}
                  onClick={() => setActiveTab("terms")}>
                  Terms & Conditions
                </SoftTypography>
              </SoftBox>

              {activeTab === "password" ? (
                <SoftBox sx={{ height: "calc(100vh - 265px)", overflowY: "auto" }}>
                  <SoftTypography
                    fontSize="16px"
                    color="text"
                    mt={2}
                    width={{ xs: "100%", md: "70%" }}>
                    To keep your account secure, please enter your current
                    password followed by a new password. Make sure the new
                    password meets the required criteria.
                  </SoftTypography>
                  <SoftBox sx={{ pb: { xs: 4, md: 8 } }}>
                    <SoftBox p={{ xs: 2, md: 3 }} sx={{ maxWidth: "430px" }}>
                      <SoftBox mb={2} sx={{ position: "relative" }}>
                        <SoftInput
                          value={form.currentPassword}
                          name="currentPassword"
                          onChange={handleChange}
                          type={showCurrentPassword ? "text" : "password"}
                          placeholder="Current Password"
                          icon={{ component: <LockIcon />, direction: "left" }}
                          sx={{
                            padding: "16px 40px 16px 4px",
                            "&.Mui-focused": {
                              padding: "16px 40px 16px 4px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                              color: "#737373 !important",
                              fontSize: "16px",
                              opacity: 1,
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "16px",
                            },
                          }}
                        />
                        <SoftBox
                          onClick={toggleCurrentPasswordVisibility}
                          sx={{
                            position: "absolute",
                            right: "12px",
                            top: "55%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "text.main",
                          }}>
                          {showCurrentPassword ? (
                            <VscEyeClosed size={20} />
                          ) : (
                            <VscEye size={20} />
                          )}
                        </SoftBox>
                      </SoftBox>

                      <SoftBox
                        sx={{
                          borderTop: "0.5px solid transparent",
                          borderColor: "border.main",
                          my: 3.4,
                          width: "100%",
                          borderImage:
                            "repeating-linear-gradient(90deg, transparent, transparent 5px, currentColor 5px, currentColor 15px) 1",
                        }}
                      />

                      <SoftBox mb={2} sx={{ position: "relative" }}>
                        <SoftInput
                          value={form.newPassword}
                          name="newPassword"
                          onChange={handleChange}
                          type={showNewPassword ? "text" : "password"}
                          placeholder="New Password"
                          icon={{ component: <LockIcon />, direction: "left" }}
                          sx={{
                            padding: "16px 40px 16px 4px",
                            "&.Mui-focused": {
                              padding: "16px 40px 16px 4px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                              color: "#737373 !important",
                              fontSize: "16px",
                              opacity: 1,
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "16px",
                            },
                          }}
                        />
                        <SoftBox
                          onClick={toggleNewPasswordVisibility}
                          sx={{
                            position: "absolute",
                            right: "12px",
                            top: "55%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "text.main",
                          }}>
                          {showNewPassword ? (
                            <VscEyeClosed size={20} />
                          ) : (
                            <VscEye size={20} />
                          )}
                        </SoftBox>
                      </SoftBox>

                      <SoftBox mb={2} sx={{ position: "relative" }}>
                        <SoftInput
                          value={form.confirmPassword}
                          name="confirmPassword"
                          onChange={handleChange}
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm Password"
                          icon={{ component: <LockIcon />, direction: "left" }}
                          sx={{
                            padding: "16px 40px 16px 4px",
                            "&.Mui-focused": {
                              padding: "16px 40px 16px 4px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                              color: "#737373 !important",
                              fontSize: "16px",
                              opacity: 1,
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "16px",
                            },
                          }}
                        />
                        <SoftBox
                          onClick={toggleConfirmPasswordVisibility}
                          sx={{
                            position: "absolute",
                            right: "12px",
                            top: "55%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            color: "text.main",
                          }}>
                          {showConfirmPassword ? (
                            <VscEyeClosed size={20} />
                          ) : (
                            <VscEye size={20} />
                          )}
                        </SoftBox>
                      </SoftBox>

                      <SoftButton
                        variant="gradient"
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        sx={{
                          padding: "14px 24px",
                          mt: 1,
                          width: "100%",
                          fontSize: "14px",
                        }}>
                        {isSubmitting ? "Saving..." : "Save Changes"}
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              ) : activeTab === "privacy" ? (
                <SoftBox
                  sx={{ height: "calc(100vh - 265px)", overflowY: "auto" }}>
                  <SoftTypography fontSize="18px" pt={2} color="lightBlack">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aenean justo lorem, dignissim et purus quis, tincidunt
                    tincidunt mi. Aenean ultrices est ac lectus scelerisque, eu
                    porttitor nibh malesuada. Praesent finibus dui id enim
                    pharetra accumsan. Aenean eu sem vehicula nisi sollicitudin
                    sodales in eu leo. Phasellus mattis lacus non arcu pretium,
                    ut porttitor sapien placerat. Sed in faucibus nulla. Donec
                    tempus neque quis augue viverra, hendrerit aliquet ipsum
                    sagittis. Cras et placerat lectus. Maecenas tortor neque,
                    pellentesque vitae porta quis, ultricies ac nulla. Interdum
                    et malesuada fames ac ante ipsum primis in faucibus. Duis
                    vehicula ac erat quis malesuada. Donec at nisi feugiat,
                    consectetur magna nec, iaculis velit. Proin lacinia
                    scelerisque nibh, id lacinia quam blandit vitae. Nulla
                    maximus tellus est, et fringilla ipsum malesuada nec.
                    <br /> Sed in sapien in justo tincidunt vulputate. Nam
                    rhoncus maximus sem, et vehicula ligula pellentesque et.
                    Aenean urna tortor, dapibus eget libero quis, vestibulum
                    faucibus erat. Nam semper feugiat est nec ullamcorper. In
                    molestie quis ante ac consectetur. Praesent id molestie quis
                    ante ac consectetur. Praesent id turpis arcu. Nam laoreet
                    lorem ipsum, gravida lobortis est consectetur a.
                    <br />
                    <br />
                    Pellentesque habitant morbi tristique senectus et netus et
                    malesuada fames ac turpis egestas. Maecenas eros magna,
                    luctus at elit porta, lacinia pretium eros. Aenean nec massa
                    eget lacus facilisis fringilla vel sed tellus. Fusce rhoncus
                    est eget metus posuere, eleifend maximus justo interdum. Sed
                    sed auctor quam. Suspendisse commodo sem diam, nec placerat
                    nibh pulvinar in. Suspendisse potenti. Nam eget neque at
                    lacus lobortis fringilla eget vestibulum mauris. Proin ac
                    ultrices ipsum. Duis sit amet tortor vitae odio sollicitudin
                    porta. Phasellus nunc tellus, varius a vestibulum quis,
                    facilisis sit amet dui. Donec sit amet ullamcorper justo,
                    ultricies consequat lacus.
                    <br />
                    <br /> Phasellus mauris ex, iaculis at erat non, scelerisque
                    consectetur ante. Pellentesque habitant morbi tristique
                    senectus et netus et malesuada fames ac turpis egestas. Duis
                    ac enim et dolor posuere dignissim. Nulla ultrices, urna a
                    luctus blandit, nibh purus malesuada diam, eget congue
                    turpis diam id dolor. Nulla eu ipsum vulputate, cursus justo
                    et, pretium odio. Quisque varius a mi sit amet placerat.
                    Curabitur ut dolor nec tortor finibus auctor. Quisque eu
                    egestas erat, vel imperdiet sapien. Sed semper urna aliquam,
                    tincidunt sem sit amet, ullamcorper neque. In mollis nulla
                    odio, in varius lacus dignissim
                  </SoftTypography>
                </SoftBox>
              ) : (
                <SoftBox
                  sx={{ height: "calc(100vh - 265px)", overflowY: "auto" }}>
                  <SoftTypography fontSize="18px" pt={2} color="lightBlack">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aenean justo lorem, dignissim et purus quis, tincidunt
                    tincidunt mi. Aenean ultrices est ac lectus scelerisque, eu
                    porttitor nibh malesuada. Praesent finibus dui id enim
                    pharetra accumsan. Aenean eu sem vehicula nisi sollicitudin
                    sodales in eu leo. Phasellus mattis lacus non arcu pretium,
                    ut porttitor sapien placerat. Sed in faucibus nulla. Donec
                    tempus neque quis augue viverra, hendrerit aliquet ipsum
                    sagittis. Cras et placerat lectus. Maecenas tortor neque,
                    pellentesque vitae porta quis, ultricies ac nulla. Interdum
                    et malesuada fames ac ante ipsum primis in faucibus. Duis
                    vehicula ac erat quis malesuada. Donec at nisi feugiat,
                    consectetur magna nec, iaculis velit. Proin lacinia
                    scelerisque nibh, id lacinia quam blandit vitae. Nulla
                    maximus tellus est, et fringilla ipsum malesuada nec.
                    <br /> Sed in sapien in justo tincidunt vulputate. Nam
                    rhoncus maximus sem, et vehicula ligula pellentesque et.
                    Aenean urna tortor, dapibus eget libero quis, vestibulum
                    faucibus erat. Nam semper feugiat est nec ullamcorper. In
                    molestie quis ante ac consectetur. Praesent id molestie quis
                    ante ac consectetur. Praesent id turpis arcu. Nam laoreet
                    lorem ipsum, gravida lobortis est consectetur a.
                    <br />
                    <br />
                    Pellentesque habitant morbi tristique senectus et netus et
                    malesuada fames ac turpis egestas. Maecenas eros magna,
                    luctus at elit porta, lacinia pretium eros. Aenean nec massa
                    eget lacus facilisis fringilla vel sed tellus. Fusce rhoncus
                    est eget metus posuere, eleifend maximus justo interdum. Sed
                    sed auctor quam. Suspendisse commodo sem diam, nec placerat
                    nibh pulvinar in. Suspendisse potenti. Nam eget neque at
                    lacus lobortis fringilla eget vestibulum mauris. Proin ac
                    ultrices ipsum. Duis sit amet tortor vitae odio sollicitudin
                    porta. Phasellus nunc tellus, varius a vestibulum quis,
                    facilisis sit amet dui. Donec sit amet ullamcorper justo,
                    ultricies consequat lacus.
                    <br />
                    <br /> Phasellus mauris ex, iaculis at erat non, scelerisque
                    consectetur ante. Pellentesque habitant morbi tristique
                    senectus et netus et malesuada fames ac turpis egestas. Duis
                    ac enim et dolor posuere dignissim. Nulla ultrices, urna a
                    luctus blandit, nibh purus malesuada diam, eget congue
                    turpis diam id dolor. Nulla eu ipsum vulputate, cursus justo
                    et, pretium odio. Quisque varius a mi sit amet placerat.
                    Curabitur ut dolor nec tortor finibus auctor. Quisque eu
                    egestas erat, vel imperdiet sapien. Sed semper urna aliquam,
                    tincidunt sem sit amet, ullamcorper neque. In mollis nulla
                    odio, in varius lacus dignissim
                  </SoftTypography>
                </SoftBox>
              )}
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Settings;
