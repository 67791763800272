import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import EditIcon from "@mui/icons-material/Edit";
import useStore from "store/hook";
import DeleteIcon from "@mui/icons-material/Delete";

const InspectionSection = ({ discipline, setDiscipline, newImages }) => {
  const { setDialogue } = useStore();

  const handleOpenDiciplineSelect = (index) => {
    setDialogue({
      type: "selectDicipline",
      isOpen: true,
      selectedDicipline: index,
    });
  };

  const handleDeleteDiscipline = () => {
    setDiscipline([]);
  };

  return (
    <>
      {discipline &&
        discipline.map((item, index) => (
          <SoftBox
            key={index}
            padding="10px"
            display="flex"
            flexDirection="column"
            borderRadius="6px"
            gap={0.5}
            backgroundColor="shadow.main"
          >
            <SoftBox display="flex" py={-2} justifyContent="space-between">
              <SoftTypography fontSize="16px" fontWeight="regular">
                {item?.disciplineName && (
                  <SoftTypography
                    component="span"
                    fontSize="16px"
                    fontWeight="bold"
                  >
                    {`${item?.disciplineName}, `}
                  </SoftTypography>
                )}
                {item?.location}
              </SoftTypography>

              <SoftBox display="flex" gap={1}>
                <DeleteIcon
                  onClick={() => handleDeleteDiscipline()}
                  sx={{
                    cursor: "pointer",
                    width: "24px",
                    height: "24px",
                    color: "error.main",
                  }}
                />
                <EditIcon
                  onClick={() => handleOpenDiciplineSelect(index)}
                  sx={{
                    cursor: "pointer",
                    width: "24px",
                    height: "24px",
                    color: "primary.main",
                    padding: "2px",
                    borderRadius: "50%",
                  }}
                />
              </SoftBox>
            </SoftBox>

            {item?.inspectionType && (
              <SoftBox display="flex" gap={0.5}>
                <SoftTypography
                  fontSize="14px"
                  fontWeight="regular"
                  color="text"
                >
                  Inspection Type:
                </SoftTypography>
                <SoftTypography
                  fontSize="14px"
                  fontWeight="regular"
                  color="lightBlack"
                >
                  {item?.inspectionType}
                </SoftTypography>
              </SoftBox>
            )}

            {item?.comment && (
              <SoftBox>
                <SoftTypography
                  fontSize="14px"
                  fontWeight="regular"
                  color="text"
                >
                  Comments
                </SoftTypography>
                <SoftTypography
                  fontSize="14px"
                  fontWeight="regular"
                  color="lightBlack"
                >
                  {item?.comment}
                </SoftTypography>
              </SoftBox>
            )}

            {item?.notes && (
              <SoftBox>
                <SoftTypography
                  fontSize="14px"
                  fontWeight="regular"
                  color="text"
                >
                  Notes
                </SoftTypography>
                <SoftTypography
                  fontSize="14px"
                  fontWeight="regular"
                  color="lightBlack"
                >
                  {item?.notes}
                </SoftTypography>
              </SoftBox>
            )}

            <SoftBox display="flex" alignItems="center" gap={1} flexWrap="wrap">
              {item?.images.map((image, index) => (
                <SoftBox position="relative" mt={1}>
                  <SoftBox
                    key={index}
                    component="img"
                    src={image}
                    sx={{
                      width: 80,
                      height: 80,
                      objectFit: "cover",
                      borderRadius: "12px",
                      border: "0.63px solid #02255B",
                    }}
                  />
                </SoftBox>
              ))}

              {newImages?.map((image, index) => (
                <SoftBox position="relative" mt={1}>
                  <SoftBox
                    key={index}
                    component="img"
                    src={URL.createObjectURL(image)}
                    sx={{
                      width: 80,
                      height: 80,
                      objectFit: "cover",
                      borderRadius: "12px",
                      border: "0.63px solid #02255B",
                    }}
                  />
                </SoftBox>
              ))}
            </SoftBox>

            {newImages?.length !== 0 && (
              <SoftBox
                display="flex"
                alignItems="center"
                gap={1}
                flexWrap="wrap"
              ></SoftBox>
            )}
          </SoftBox>
        ))}
    </>
  );
};

export default InspectionSection;
