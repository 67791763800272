import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftAvatar from "components/SoftAvatar";
import SearchIcon from "@mui/icons-material/Search";
import api from "store/api/api";

export default function SelectInspector({ setSelectedInspectors }) {
  const { store, setDialogue } = useStore();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [inspectors, setInspectors] = React.useState([]);

  const { isOpen = false } = store?.app?.dialogs?.selectInspector || {};

  React.useEffect(() => {
    getInspectorsList();
  }, [searchQuery]);

  const getInspectorsList = async () => {
    const response = await api.getInspectorsList({ searchData: searchQuery });
    if (response.status === 1) {
      setInspectors(response.data);
    }
  };

  const handleClose = () =>
    setDialogue({ type: "selectInspector", isOpen: false });

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const handleSelect = (inspector) => {
    setSelectedInspectors((prev) => {
      if (prev.some((item) => item._id === inspector._id)) {
        return prev;
      }
      return [...prev, inspector];
    });
    handleClose();
  };

  const filteredInspectors = React.useMemo(() => {
    return inspectors;
  }, [inspectors, searchQuery]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      aria-labelledby="select-inspector-dialog"
      sx={{
        "& .MuiDialog-paper": {
          margin: 2,
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: "primary.main" }}>
        <SoftBox>
          <SoftInput
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search..."
            icon={{ component: <SearchIcon />, direction: "left" }}
            sx={{
              width: "100%",
              padding: "15px 12px 15px 4px",
              "&.Mui-focused": {
                padding: "15px 12px 15px 4px",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#212121 !important",
                fontSize: "16px",
                opacity: 1,
              },
              "& .MuiInputBase-input": {
                fontSize: "16px",
              },
            }}
          />
        </SoftBox>
      </DialogTitle>
      <DialogContent
        sx={{ backgroundColor: "#ECECEC", padding: "18px 24px !important" }}
      >
        <SoftBox
          sx={{
            backgroundColor: "white.main",
            maxHeight: "600px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {filteredInspectors?.length > 0 ? (
            filteredInspectors?.map((inspector) => (
              <SoftBox
                key={inspector.id}
                onClick={() => handleSelect(inspector)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                  "&:hover": {
                    backgroundColor: "info.main",
                    "& .MuiTypography-root": {
                      color: "white.main",
                    },
                    "& .inspector-name": {
                      fontSize: "18px",
                      fontWeight: "bold",
                    },
                    "& .inspector-email": {
                      fontSize: "16px",
                      fontWeight: "medium",
                    },
                  },
                }}
              >
                <SoftAvatar
                  src={inspector.profile_picture}
                  alt={inspector.fullName}
                  size="lg"
                  variant="rounded"
                  sx={{ mr: 2 }}
                />
                <SoftBox display="flex" flexDirection="column" gap={0.6}>
                  <SoftTypography
                    className="inspector-name"
                    variant="button"
                    fontWeight="medium"
                    fontSize="16px"
                    color="lightBlack"
                    textTransform="none"
                  >
                    {inspector.full_name}
                  </SoftTypography>
                  <SoftTypography
                    className="inspector-email"
                    variant="caption"
                    fontSize="14px"
                    color="lightBlack"
                    sx={{ display: "block" }}
                  >
                    {inspector.email}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            ))
          ) : (
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="70px"
            >
              <SoftTypography color="lightBlack" fontWeight="bold">
                No Manager Found
              </SoftTypography>
            </SoftBox>
          )}
        </SoftBox>
      </DialogContent>
    </Dialog>
  );
}
