import React from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import truncateString from "../../services/truncatestring"

function Author({ image, name, email, is_deleted, user_id, type }) {
  return (
    <SoftBox display="flex" alignItems="center" pr="14px" pt="8px">
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="lg" variant="rounded" />
      </SoftBox>

      <SoftBox display="flex" flexDirection="column">
        <SoftTypography
          variant="button"
          fontWeight="medium"
          textTransform="none"
          color="lightBlack">
          {name}
        </SoftTypography>
        {is_deleted ? (
          <SoftTypography
            sx={{ fontSize: 10 }}
            color="error"
            fontWeight="medium">
            {type + " deleted"}
          </SoftTypography>
        ) : null}
      </SoftBox>
    </SoftBox>
  );
}

export const columns = [
  { name: "Project Name", align: "left" },
  { name: "Project Manager", align: "left" },
  { name: "# Inspections", align: "center" },
  { name: "Location", align: "center" },
  { name: "Action", align: "center" },
];

function Rows(data, setDialogue) {

  const names = data.manager.map(user => user.full_name).join(", ");

  return {
    linkPath: `/project-overview/${data._id}`,
    noLinkCells: ['Action'],
    "Project Name": (
        <SoftTypography
          variant="text"
          color="lightBlack"
          fontWeight="medium"
          textTransform="capitalize"
          padding="0px 0px 0px 15px !important"    
          sx={{
            display: "block",
            whiteSpace: "normal",
            wordWrap: "break-word",
            maxWidth: "200px",
            lineHeight: "21px",
          }}>
         {data.name}
        </SoftTypography>
    ),

    "Project Manager": (
      <SoftTypography
      variant="text"
      color="lightBlack"
      fontWeight="medium"
      textTransform="capitalize"
      sx={{
        display: "block",
        whiteSpace: "normal",
        wordWrap: "break-word",
        maxWidth: "250px",
            lineHeight: "21px",
      }}>
      {truncateString(names, 40)}
    </SoftTypography>
    ),

    "# Inspections": (
      <SoftTypography variant="h3" color="primary" fontWeight="bold">
        {data?.inspection_count}
      </SoftTypography>
    ),
    Location: (
      <SoftTypography
        variant="caption"
        color="black"
        fontWeight="regular"
        textTransform="capitalize"
        sx={{
          display: "block",
          fontSize: "14px",
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "200px",
          lineHeight: "21px",
        }}>
        {data.location}
      </SoftTypography>
    ),
    Action: (
      <SoftBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={3}
        onClick={(e) => e.stopPropagation()}>
        <DeleteIcon
          sx={{
            cursor: "pointer",
            width: "24px",
            height: "24px",
            color: "error.main",
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDialogue({
              type: "deleteProject",
              isOpen: true,
              projectId: data._id,
              is_deleted: true,
            });
          }}
        />
        <Link 
          to={`/projects/edit/${data._id}`}
          onClick={(e) => e.stopPropagation()}>
          <EditIcon
            sx={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
              color: "white.main",
              backgroundColor: "primary.main",
              padding: "2px",
              borderRadius: "50%",
              marginTop: "8px",
            }}
          />
        </Link>
      </SoftBox>
    ),
  };
}

Author.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  user_id: PropTypes.string,
  type: PropTypes.string,
  is_deleted: PropTypes.bool,
};

export default Rows;
