// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import PageLayout from "examples/LayoutContainers/PageLayout";

function CoverLayout({
  color,
  header,
  title,
  description,
  image,
  top,
  children,
}) {
  return (
    <PageLayout background="white">
      <Grid
        container
        sx={{
          minHeight: "100vh",
          margin: 0,
        }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <SoftBox maxWidth="420px" mx="auto" width="420px">
            <SoftBox py={3} px={1}>
              {!header ? (
                <>
                  <SoftBox>
                    <SoftTypography
                      fontSize="44px"
                      color={color}
                      sx={{
                        fontWeight: "700",
                      }}
                      textGradient>
                      {title}
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography
                    fontWeight="regular"
                    fontSize="16px"
                    color="text"
                    sx={{
                      wordSpacing: "1px",
                    }}>
                    {description}
                  </SoftTypography>
                </>
              ) : (
                header
              )}
            </SoftBox>
            <SoftBox pb={3} px={1}>
              {children}
            </SoftBox>
          </SoftBox>
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: { xs: "none", md: "block" },
            position: "relative",
            overflow: "hidden",
            boxShadow: "0 0 50px 0 rgba(0, 0, 0, 0.1)",
          }}>
          <SoftBox
            sx={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              height: "70%",
              backgroundImage: `url(${image})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
          <SoftBox
            sx={{
              color: "#323232",
              position: "absolute",
              bottom: "20px",
              right: "20px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}>
            {/* <SoftTypography textTransform="none" fontSize="16px">
              Powered by
              <span style={{ fontWeight: "bold" }}> NAPWORKS</span>
            </SoftTypography>
            <NapworksLogo /> */}
          </SoftBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  color: "info",
  top: 20,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
