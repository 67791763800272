import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { ReactComponent as DeleteProjectIcon } from "assets/images/small-logos/logo-deleteProject.svg";
import SoftBox from "components/SoftBox";
import api from "store/api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import formatStringWithSpaces from "services/stringconverter";
import CircularLoader from "./Loader";

export default function ChangeStatusOfInspectionAdmin() {
  const { store, setDialogue } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  

  const {
    isOpen = false,
    inspectionId = "",
    status = "",
  } = store?.app?.dialogs?.changeInspectionStatusAdmin || {};

  const { success } = store?.app?.dialogs?.isSuccess || {};

  let statusToChange =
    status == "ready_for_distribution";

  const handleClose = () =>
    setDialogue({ type: "changeInspectionStatusAdmin", isOpen: false });

  const handleChangeStatus = async () => {
    setLoading(true);
    try {
      const updateData = {
        inspection_id: inspectionId
      };

      const response = await api.completeInspectionStatus(updateData);

      if (response?.status == 1) {
        toast.success(formatStringWithSpaces(response?.message));
        setDialogue({ type: "isSuccess", success: !success });
      } else {
        toast.error(formatStringWithSpaces(response?.message));
      }
      handleClose();
    } catch (error) {
      console.error("Error updating Inspection status:", error);
    }
    finally{
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            px: 14,
            pb: 2,
            pt: 3,
            margin: 0,
            borderRadius: "12px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ pb: 1.5 }}>
          <SoftTypography
            variant="h5"
            color="lightBlack"
            fontWeight="bold"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 16,
              alignItems: "center",
            }}
          >
            <SoftBox sx={{ display: "flex", position: "relative" }}>
              <DeleteProjectIcon style={{ width: "90px", height: "90px" }} />
            </SoftBox>
            {"Approve Inspection"}
          </SoftTypography>
        </DialogTitle>
        <DialogContent sx={{ px: 0 }}>
          <DialogContentText id="alert-dialog-description">
            <SoftTypography
              variant="h6"
              fontWeight="regular"
              textAlign="center"
              sx={{ color: "lightBlack.main" }}
            >
              Do you want to approve this inspection and send directly for distribution?
            </SoftTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            gap: 3,
            padding: "0px",
          }}
        >
          <SoftButton
            variant="gradient"
            color="error"
            sx={{
              width: "100%",
              padding: "14px 24px",
              fontSize: "16px",
            }}
            onClick={handleChangeStatus}
          >
            {loading == true ? (
              <CircularLoader type="small" />
            ) : (
              "Yes, approve"
            )}
          </SoftButton>
          <SoftTypography
            fontSize="16px"
            fontWeight="bold"
            align="center"
            color="primary"
            sx={{ margin: "0px !important", cursor: "pointer" }}
            onClick={handleClose}
          >
            Cancel
          </SoftTypography>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
