import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import api from "store/api/api";
import { DialogActions, Grid } from "@mui/material";
import SoftButton from "components/SoftButton";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import convertMillisecondsToDate from "services/dateconverter";
import formatStringWithSpaces from "services/stringconverter";
import truncateString from "services/truncatestring";
import CircularLoader from "./Loader";
import { ReactComponent as EmptyFolderIcon } from "assets/images/small-logos/icon-emptyFolder.svg";

export default function SelectInspection({
  projectid,
  referenceData,
  setReferenceData,
  curretnInspectionId,
}) {
  const { store, setDialogue } = useStore();
  const [inspections, setInspections] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);

  const { isOpen = false } = store?.app?.dialogs?.selectInspection || {};

  const [selectedInspection, setSelectedInspection] = React.useState(
    referenceData?._id
  );
  const [selectedInspectionData, setSelectedInspectionData] = React.useState();

  React.useEffect(() => {
    fetchInspectionList();
    setSelectedInspection(
      JSON.stringify(referenceData) !== "{}" ? referenceData?._id : ""
    );
  }, [isOpen, page]);

  const handleClose = () => {
    setDialogue({ type: "selectInspection", isOpen: false });
  };

  const handleAdd = () => {
    setReferenceData({
      deficiencies_found: selectedInspectionData?.deficiencies_found,
      inspection_number: selectedInspectionData?.inspection_number,
      temperature: selectedInspectionData?.temperature,
      weather_condition: selectedInspectionData?.weather_condition,
      _id: selectedInspectionData?._id,
    });
    handleClose();
  };

  const fetchInspectionList = async () => {
    setIsLoading(true);
    try {
      const response = await api.getInspectionListByProject({
        project_id: projectid,
        current_refference_id: curretnInspectionId,
        page: page,
        limit: 50,
      });
      setInspections(response?.data?.inspections);
    } catch (error) {
      console.error("Error fetching project Inspections:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      aria-labelledby="select-inspector-dialog"
      sx={{
        "& .MuiDialog-paper": {
          margin: 2,
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent
        sx={{ backgroundColor: "white.main", padding: "18px 24px !important" }}
      >
        <SoftBox display="flex" alignItems="center" justifyContent="center">
          <SoftTypography
            color="lightBlack"
            fontSize="26px"
            fontWeight="bold"
            sx={{ color: "#212121" }}
          >
            CHOOSE YOUR INSPECTION{" "}
          </SoftTypography>
        </SoftBox>

        {isLoading ? (
          <SoftBox
            display="flex"
            height="calc(100vh - 180px)"
            justifyContent="center"
            alignItems="center"
            p={3}
          >
            <SoftTypography variant="subtitle1" color="textSecondary">
              <CircularLoader type="large" />
            </SoftTypography>
          </SoftBox>
        ) : inspections?.length > 0 ? (
          <Grid container md={12} spacing={1.5} py={5}>
            {inspections?.map((inspection, index) => (
              <Grid md={6} xs={12} item key={index}>
                <InspectionCard
                  status={inspection?.status}
                  temperature={inspection?.temperature}
                  name={inspection?.project_name}
                  deficiency={inspection?.deficiencies_found}
                  weather={inspection?.weather_condition}
                  number={inspection?.inspection_number}
                  time={inspection?.createdTime}
                  inspectionId={inspection?._id}
                  id={selectedInspection || referenceData?._id}
                  setSelectedInspection={setSelectedInspection}
                  inspectionData={inspection}
                  setSelectedInspectionData={setSelectedInspectionData}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <SoftBox display="flex" flexDirection="column" alignItems="center">
            <SoftTypography
              fontSize="90px"
              fontWeight="light"
              height="110px"
              color="fade"
            >
              0
            </SoftTypography>
            <SoftTypography
              fontSize="40px"
              fontWeight="medium"
              color="fade"
              pb={2}
            >
              Insepctions
            </SoftTypography>
            <EmptyFolderIcon style={{ opacity: 0.8 }} />
          </SoftBox>
        )}

        <SoftBox display="flex" flexDirection="column" gap={2}>
          <DialogActions
            sx={{
              justifyContent: "center",
              flexDirection: "column",
              gap: 3,
              padding: "0px",
            }}
          >
            <SoftButton
              variant="gradient"
              color="primary"
              sx={{
                width: "45%",
                padding: "14px 24px",
                fontSize: "16px",
              }}
              onClick={handleAdd}
            >
              Add
            </SoftButton>
            <SoftTypography
              fontSize="16px"
              fontWeight="bold"
              align="center"
              color="primary"
              sx={{ margin: "0px !important", cursor: "pointer" }}
              onClick={handleClose}
            >
              Cancel
            </SoftTypography>
          </DialogActions>
        </SoftBox>
      </DialogContent>
    </Dialog>
  );
}

const InspectionCard = ({
  status,
  temperature,
  name,
  deficiency,
  weather,
  number,
  time,
  inspectionId,
  id,
  setSelectedInspection,
  inspectionData,
  setSelectedInspectionData,
}) => {
  return (
    <SoftBox
      display="flex"
      alignItems="center"
      py={0}
      gap={1.5}
      position="relative"
      width="100%"
      sx={{
        cursor: "pointer",
        border: id === inspectionId ? "2px solid #02255B" : "2px solid #FFFFFF",
        padding: "3px",
        borderRadius: "8px",
      }}
      onClick={() => {
        setSelectedInspection(inspectionId);
        setSelectedInspectionData(inspectionData);
      }}
    >
      <SoftBox
        position="absolute"
        py="2px"
        sx={{
          width: 80,
          height: 80,
          backgroundColor: "#02255B",
          color: "#FFFFFF",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2px",
          gap: "2px",
          left: "20px",
        }}
      >
         <SoftTypography
          fontSize="12px"
          pb={0.5}
          sx={{ color: "#FFFFFF", fontWeight: "bold",   lineHeight: "12px" }}
          textTransform="uppercase"
        >
          INSPECTION
        </SoftTypography> 

        <SoftTypography
          fontSize="25px"
          sx={{ color: "#FFFFFF", fontWeight: "bold", lineHeight: "36px" }}
          textTransform="capitalize"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          width={93}
        >
          {number}
        </SoftTypography>
      </SoftBox>

      <SoftBox
        py={0}
        display="flex"
        width="100%"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="start"
      >
        <SoftTypography
          fontSize="10px"
          ml={15}
          sx={{ color: "#02255B", fontWeight: "medium", lineHeight: "12px" }}
          textTransform="capitalize"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {convertMillisecondsToDate(time)}
        </SoftTypography>

        <SoftBox sx={{ backgroundColor: "#F1F4F8" }} my={0.5} width="100%">
          <SoftTypography
            fontSize="14px"
            sx={{ color: "#000000", fontWeight: "Bold", lineHeight: "21px" }}
            textTransform="capitalize"
            display="flex"
            alignItems="center"
            justifyContent="start"
            ml={15}
          >
            {name}
          </SoftTypography>
          <SoftTypography
            fontSize="10px"
            sx={{ color: "#02255B", fontWeight: "medium", lineHeight: "15px" }}
            textTransform="capitalize"
            display="flex"
            alignItems="center"
            justifyContent="start"
            ml={15}
          >
            Deficiency Found :
            <SoftTypography
              fontSize="12px"
              sx={{
                color: "#02255B",
                fontWeight: "medium",
                lineHeight: "21px",
              }}
              textTransform="capitalize"
            >
              {`${truncateString(
                formatStringWithSpaces(deficiency.join(", ")),
                14
              )}`}
            </SoftTypography>
          </SoftTypography>
        </SoftBox>

        <SoftTypography
          fontSize="12px"
          sx={{
            fontWeight: "bold",
            lineHeight: "18px",
            color:
              status === "report_created"
                ? "#006903"
                : status === "contractor_not_ready"
                ? "#BC6809"
                : status === "cancelled_in_route"
                ? "#105DB5"
                : "lightBlack",
          }}
          textTransform="capitalize"
          display="flex"
          alignItems="center"
          justifyContent="center"
          ml={15}
        >
          {formatStringWithSpaces(status)}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};
