import React from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as OnStatusIcon } from "assets/images/small-logos/logo-onStatus.svg";
import EditIcon from "@mui/icons-material/Edit";

export const columns = [
  { name: "Project Name", align: "left", width: "40%", },
  { name: "Location", align: "center", width: "20%" },
  { name: "Status", align: "center", width: "20%" },
  { name: "Action", align: "center", width: "20%" },
];

function Rows(data) {
  return {
    "Project Name": (
      <SoftBox display="flex" flexDirection="column" pl={2} py={1.3} gap={1}>
        <SoftTypography
          fontSize="16px"
          color="lightBlack"
          fontWeight="medium"
          textTransform="none"
          padding="0px 0px !important">
          Assoc & Clemson Univ Foundation
        </SoftTypography>
        <SoftTypography color="text.main" fontSize="14px">
          #234235453465
        </SoftTypography>
      </SoftBox>
    ),
    Location: (
      <SoftTypography
        variant="text"
        color="lightBlack"
        fontWeight="regular"
        textTransform="none"
        sx={{
          display: "block",
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "180px",
          lineHeight: "21px",
        }}>
        Terminal, 45 Grand Central, New York, NY
      </SoftTypography>
    ),
    Status: (
      <SoftTypography
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          height: "45px",
          width: "50px",
          margin: "0 auto",
        }}>
        {/* {data.is_active ? <OnStatusIcon /> : <OffStatusIcon />} */}
        <OnStatusIcon />
      </SoftTypography>
    ),
    Action: (
      <SoftBox display="flex" alignItems="center" justifyContent="center" gap={3}>
        <DeleteIcon
          sx={{
            cursor: "pointer",
            width: "24px",
            height: "24px",
            color: "error.main",
          }}
        />
        <EditIcon
          sx={{
            cursor: "pointer",
            width: "20px",
            height: "20px",
            color: "white.main",
            backgroundColor: "primary.main",
            padding: "2px",
            borderRadius: "50%",
          }}
        />
      </SoftBox>
    ),
  };
}

export default Rows;
