import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Card, Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import Location from "layouts/tables/Location";
import Comment from "layouts/tables/Comment";


function Table({ columns, rows, expandDisciplineId }) {
  const { lightBlack, shadow } = colors;
  const { size, fontWeightBold } = typography;
  
  const [expandedRow, setExpandedRow] = useState("");

  React.useEffect(() => {
    setExpandedRow((prev) =>
      prev == expandDisciplineId
        ? "" 
        : expandDisciplineId
    );
  }, [expandDisciplineId]);

  const renderColumns = columns.map(({ name, align, width }, key) => (
    <SoftBox
      key={name}
      component="th"
      width={width || "auto"}
      pt={1.75}
      pb={1.75}
      pl={align === "left" ? 3 : 1}
      pr={align === "right" ? 3 : 1}
      textAlign={align}
      fontSize={size.lg}
      fontWeight={fontWeightBold}
      color={lightBlack.main}
      backgroundColor={shadow.main}
      borderBottom="none !important"
    >
      {name}
    </SoftBox>
  ));

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;
    const isEvenRow = key % 2 === 0;
    const linkPath = row.linkPath;
    const noLinkCells = row.noLinkCells || [];
    const inspectionTypes = row?.inspectionTypes;
    const comments = row?.comments;
    const  disciplineId = row?.disciplineId;
    const isExpanded = expandedRow === disciplineId;
     
    const tableRow = columns.map(({ name, align }) => (
      <SoftBox
        key={uuidv4()}
        component="td"
        px={1}
        py={1.5}
        textAlign={align}
        sx={{
          backgroundColor: isEvenRow ? "white" : "#F1F4F8",
          verticalAlign: "middle",
        }}
      >
        <SoftTypography
          variant="button"
          fontWeight="regular"
          color="secondary"
          sx={{ display: "inline-block", width: "max-content" }}
        >
          {row[name]}
        </SoftTypography>
      </SoftBox>
    ));

    return (
      <React.Fragment key={rowKey}>
        <TableRow >
          {linkPath ? (
            <>
              {tableRow.map((cell, index) => (
                <React.Fragment key={index}>
                  {noLinkCells.includes(columns[index].name) ? (
                    cell
                  ) : (
                    <Link
                      to={linkPath}
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "contents",
                      }}
                    >
                      {cell}
                    </Link>
                  )}
                </React.Fragment>
              ))}
            </>
          ) : (
            tableRow
          )}
        </TableRow>

        {/* Expanded Row */}
        {isExpanded && (
          <TableRow>
            <td colSpan={columns.length}>
              <SoftBox
                sx={{
                  backgroundColor: "white",
                  borderRadius: "4px",
                  marginTop: "5px",
                   height: 500, 
                   overflowY: "auto", 
                   "&::-webkit-scrollbar":{
                    display:"none"
                   }
                }}

              >
                <Location inspectionTypes={inspectionTypes} disciplineId={disciplineId}/>
                <Comment comments={comments} disciplineId={disciplineId}/>
              </SoftBox>
            </td>
          </TableRow>
        )}
      </React.Fragment>
    );
  });

  return useMemo(
    () => (
      <TableContainer  sx={{
        borderRadius: 0,
        height: "calc(100vh - 200px)",
        overflowY: "auto",
      }}>
        <MuiTable sx={{ borderRadius: 0 }}>
          <SoftBox component="thead" sx={{ backgroundColor: "white", position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>{renderColumns}</TableRow>
          </SoftBox>
          <TableBody >{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows, expandedRow] 
  );
}

Table.defaultProps = {
  columns: [],
  rows: [{}],
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
