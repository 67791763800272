import React from "react";
import { CircularProgress, Box } from "@mui/material";

const CircularLoader = ({type}) => {

    const loaderStyles = {
        small: { size: 25, thickness: 3, color: "#FFFFFF" },
        large: { size: 50, thickness: 4, color: "#02255B" },  

      };
    
      const { size, thickness, color } = loaderStyles[type] ;
    
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={size} thickness={thickness} sx={{ color }} />
        </Box>
      );
};

export default CircularLoader;
