import * as React from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/logos/image.png";
import { useNavigate } from "react-router-dom";
import api from "store/api/api";

function ForgotPassword() {
  const navigate = useNavigate();
  const [otpValues, setOtpValues] = React.useState(["", "", "", ""]);
  const [error, setError] = React.useState(false);
  const inputRefs = React.useRef([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isResendActive, setIsResendActive] = React.useState(true);
  const [countdown, setCountdown] = React.useState(0);

  const email = React.useMemo(() => localStorage.getItem("resetEmail"), []);

  const handleResendOTP = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsResendActive(false);
      setCountdown(59);
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  React.useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsResendActive(true);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  // Handle OTP input changes
  const handleOtpChange = (index, value) => {
    if (isNaN(value)) return; // Only allow numbers

    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    // Auto focus next input
    if (value !== "" && index < 3) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Handle backspace
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      if (otpValues[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const submitForm = async () => {
    try {
      setIsLoading(true);
      const otp = otpValues.join("");

      // const response = await api.verifyOtp({
      //   email,
      //   otp
      // });
      // if (response.status === 1) {
      //   setError(false);
      //   navigate("/authentication/new-password");
      // } else {
      //   setError(true);
      // }

      if (otp === "5990") {
        setError(false);
        navigate("/authentication/new-password");
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CoverLayout
      title="OTP Verification"
      color="primary"
      description="Enter the verification code we just sent on your email address."
      image={curved9}>
      <SoftBox component="form" role="form">
        {error && (
          <SoftBox>
            <SoftTypography variant="caption" color="warning" fontSize="14px">
              Wrong verification code, Please enter code again
            </SoftTypography>
          </SoftBox>
        )}
        <SoftBox
          mb={1}
          display="flex"
          justifyContent="space-between"
          width="100%">
          {otpValues.map((value, index) => (
            <SoftInput
              key={index}
              value={value}
              onChange={(e) => handleOtpChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              inputRef={(el) => (inputRefs.current[index] = el)}
              type="text"
              maxLength={1}
              sx={{
                width: "50px",
                height: "60px !important",
                padding: "0px",
                textAlign: "center",
                border: "0.5px solid #6A707C",
                backgroundColor: error && "#FFA5A5 !important",
                "& input": {
                  textAlign: "center",
                },
                marginRight: index < 3 ? "10px" : 0,
              }}
            />
          ))}
        </SoftBox>
        <SoftBox mt={2} mb={1}>
          {isResendActive ? (
            <SoftTypography
              variant="caption"
              fontSize="14px"
              color="dark"
              sx={{ cursor: "pointer" }}
              onClick={handleResendOTP}>
              Didn't received code?{" "}
              <SoftTypography
                component="span"
                variant="caption"
                color="info"
                fontSize="14px"
                fontWeight="bold">
                Resend
              </SoftTypography>
            </SoftTypography>
          ) : (
            <SoftTypography variant="caption" color="dark" fontSize="14px">
              Resend OTP in{" "}
              <SoftTypography
                component="span"
                variant="caption"
                color="dark"
                fontSize="14px"
                fontWeight="bold">
                00:{String(countdown).padStart(2, "0")}
              </SoftTypography>
            </SoftTypography>
          )}
        </SoftBox>

        <SoftBox mt={4} mb={1} width="75%">
          <SoftButton
            onClick={submitForm}
            variant="gradient"
            color="primary"
            sx={{
              fontSize: "16px",
              padding: "14px 24px",
              fontWeight: "bold",
            }}
            fullWidth>
            {isLoading ? "Verifying..." : "Verify"}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default ForgotPassword;
