import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import api from "store/api/api";
import { Collapse, DialogActions, TextField, Typography } from "@mui/material";
import SoftButton from "components/SoftButton";
import { Box, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SoftInput from "components/SoftInput";
import { toast } from "react-toastify";
import CircularLoader from "./Loader";


export default function AddDicipline() {
  const { store, setDialogue } = useStore();
  const [loading, setLoading] = React.useState(false);
 
  const { isOpen = false } = store?.app?.dialogs?.addDicipline || {};

  const [discipline, setDiscipline] = React.useState({
    type: "custom",
    name: ""
  })

  const AddDiscipline = async () => {
       setLoading(true);
      try {
        const response = await api.addDiscipline(discipline);
        if(response.status === 1) {
            setDiscipline((prev) => ({ ...prev, name: "" }));
            handleClose();
        } else {
            toast.error(response?.message || "Error  In Add Discipline");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
      finally{
         setLoading(false);
      }
    }

  const handleClose = () =>
    setDialogue({ type: "addDicipline", isOpen: false, isaddDicipline:false });

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      aria-labelledby="select-inspector-dialog"
      sx={{
        "& .MuiDialog-paper": {
          margin: 2,
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent
        sx={{ backgroundColor: "white.main", padding: "18px 24px !important" }}
      >
        <SoftBox display="flex" flexDirection="column" gap={3}>

          <SoftBox
            mt={3}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            textAlign="center"
          >
            <SoftTypography
              fontSize="26px"
              fontWeight="bold"
              sx={{ color: "#212121" }}
            >
              ADD NEW DISCIPLINE
            </SoftTypography>

            <SoftTypography
              fontSize="14px"
              fontWeight="400"
              sx={{
                color: "#737373",
                maxWidth: "70%",
              }}
            >
              Don't worry if you can't find a discipline. You can add a new one
              by filling out the form here.
            </SoftTypography>
          </SoftBox>

          <SoftBox
          >

          <SoftInput
            value={discipline?.name}
            onChange={(e) => setDiscipline((prev) => ({
              ...prev,
              name: e?.target?.value
            }))}
            name="dicipline"
            placeholder="Enter New Dicipline Field"
            sx={{
              padding: "14px 12px",
              "& .MuiInputBase-input::placeholder": {
                color: "#737373 !important",
                fontSize: "16px",
                opacity: 1,
              },
            }}
          />
          </SoftBox>

          <DialogActions
            sx={{
              justifyContent: "center",
              flexDirection: "column",
              gap: 3,
              padding: "0px",
            }}
          >
            <SoftButton
              variant="gradient"
              color="primary"
              sx={{
                width: "45%",
                padding: "14px 24px",
                fontSize: "16px",
              }}
              onClick={AddDiscipline}
            >
              {loading == true ? <CircularLoader type="small"/> : "Add"} 
            </SoftButton>
            <SoftTypography
              fontSize="16px"
              fontWeight="bold"
              align="center"
              color="primary"
              sx={{ margin: "0px !important", cursor: "pointer" }}
              onClick={handleClose}
            >
              Cancel
            </SoftTypography>
          </DialogActions>
        </SoftBox>
      </DialogContent>
      
    </Dialog>
  );
}
