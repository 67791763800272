import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { IoMdWarning } from "react-icons/io";

export default function ApiResponse() {
  const { store, setDialogue } = useStore();
  
  const {
    app: {
      dialogs: {
        apiResponse: { isOpen, message },
      },
    },
  } = store;

  const handleClose = () =>
    setDialogue({ isOpen: false, message: "", type: "apiResponse" });

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            px: 14,
            pb: 2,
            pt: 3,
            margin: 0,
            borderRadius: "12px",
          },
        }}>
        <DialogTitle id="alert-dialog-title" sx={{ pb: 1.5 }}>
          <SoftTypography
            variant="h5"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 16,
              alignItems: "center",
              fontWeight: "bold",
            }}>
            <IoMdWarning fontSize="56px" /> {"Warning"}
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <SoftTypography
              variant="h6"
              fontWeight="regular"
              sx={{ color: "lightBlack.main", textAlign: "center" }}>
              {message}
            </SoftTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <SoftButton
            variant="gradient"
            color="error"
            sx={{ width: "100%",
              padding: "14px 24px",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            onClick={handleClose}>
            OK
          </SoftButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
