import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useStore from "store/hook";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { MdArrowRightAlt } from "react-icons/md";
import SoftBox from "components/SoftBox";
import { useNavigate } from "react-router-dom";

export default function LogoutUser() {
  const { store, setDialogue } = useStore();
  const navigate = useNavigate();

  const { isOpen = false, message = "" } =
    store?.app?.dialogs?.logoutUser || {};

  const handleClose = () => setDialogue({ type: "logoutUser", isOpen: false });

  const handleLogout = () => {
    localStorage.removeItem("loginData");
    handleClose();
    navigate("/authentication/sign-in");
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            px: 14,
            pb: 2,
            pt: 3,
            margin: 0,
            borderRadius: "12px",
          },
        }}>
        <DialogTitle id="alert-dialog-title" sx={{ pb: 1.5 }}>
          <SoftTypography
            variant="h5"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: 16,
              alignItems: "center",
              fontWeight: "bold",
            }}>
            <SoftBox
              sx={{
                backgroundColor: "darkOrange.main",
                borderRadius: "50%",
                width: "70px",
                height: "70px",
                position: "relative",
                overflow: "hidden",
              }}>
              <MdArrowRightAlt
                fontSize="80px"
                color="white"
                style={{
                  position: "absolute",
                  right: "-13px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
            </SoftBox>{" "}
            {"Logout"}
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <SoftTypography
              variant="h6"
              fontWeight="regular"
              sx={{ color: "lightBlack.main" }}>
              {message}
            </SoftTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            flexDirection: "column",
            gap: 3,
            padding: "0px",
          }}>
          <SoftButton
            variant="gradient"
            color="error"
            sx={{
              width: "100%",
              padding: "14px 24px",
              fontSize: "16px",
              fontWeight: "bold",
            }}
            onClick={handleLogout}>
            Yes, Logout
          </SoftButton>
          <SoftTypography
            fontSize="16px"
            fontWeight="600"
            align="center"
            color="primary"
            sx={{ margin: "0px !important", cursor: "pointer" }}
            onClick={handleClose}>
            Cancel
          </SoftTypography>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
