import * as React from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";
import api from "store/api/api";
import useStore from "store/hook";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/logos/image.png";
import CircularLoader from "components/SoftDialog/Loader";

function SignIn() {
  const { setDialogue } = useStore();
  const [form, setForm] = React.useState({
    email: "",
    password: "",
    device_type: "web",
    login_type: "email",
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) =>
    setForm((data) => ({ ...data, [e.target.name]: e.target.value }));

  const submitForm = async () => {
    setLoading(true);
    try {
      form.email = form.email.toLocaleLowerCase();

      const response = await api.login(form);
      if (response.status === 1) {
        const {
          token,
          email,
          fullName,
          profile_picture,
          account_type,
          userId,
        } = response.data;

        const loginData = {
          isLoggedIn: true,
          token,
          email,
          fullName,
          profile_picture,
          account_type,
          userId,
        };
        localStorage.setItem("loginData", JSON.stringify(loginData));
        window.location.href = "/accounts";
      } else {
        setDialogue({
          type: "apiResponse",
          isOpen: true,
          message: response.message,
        });
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <CoverLayout
      title="Sign In,"
      color="primary"
      description="Enter your email and password to sign in"
      image={curved9}
    >
      <SoftBox component="form" role="form" width="100%">
        <SoftBox mb={3}>
          <SoftInput
            value={form.email}
            name="email"
            onChange={handleChange}
            type="email"
            placeholder="Email Address"
            sx={{
              padding: "16px 12px 16px 4px",
              "&.Mui-focused": {
                padding: "16px 42px 16px 4px",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#737373 !important",
                fontSize: "16px",
                opacity: 1,
              },
              "& .MuiInputBase-input": {
                fontSize: "16px",
              },
            }}
            icon={{ component: <EmailIcon />, direction: "left" }}
          />
        </SoftBox>
        <SoftBox mb={2} sx={{ position: "relative" }}>
          <SoftInput
            value={form.password}
            name="password"
            onChange={handleChange}
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            sx={{
              padding: "16px 42px 16px 4px",
              "&.Mui-focused": {
                padding: "16px 42px 16px 4px",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#737373 !important",
                fontSize: "16px",
                opacity: 1,
              },
              "& .MuiInputBase-input": {
                fontSize: "16px",
              },
            }}
            icon={{
              component: <LockIcon />,
              direction: "left",
            }}
          />
          <SoftBox
            onClick={togglePasswordVisibility}
            sx={{
              position: "absolute",
              right: "12px",
              top: "55%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              color: "text.main",
            }}
          >
            {showPassword ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
          </SoftBox>
        </SoftBox>

        <SoftBox mt={5} mb={1} width="80%">
          <SoftButton
            onClick={submitForm}
            variant="gradient"
            color="primary"
            sx={{
              fontSize: "16px",
              padding: "14px 24px",
              fontWeight: "bold",
            }}
            fullWidth
          >
            {loading == true ? <CircularLoader type="small" /> : "Sign In"}
          </SoftButton>
        </SoftBox>

        <SoftBox mt={3} mb={1} width="75%">
          <SoftTypography
            component={Link}
            to="/authentication/forgot-password"
            fontSize="16px"
            fontWeight="bold"
            color="info"
            sx={{ cursor: "pointer" }}
          >
            Forgot Password?
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
