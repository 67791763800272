import * as React from "react";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import LockIcon from "@mui/icons-material/Lock";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/logos/image.png";
import { useNavigate } from "react-router-dom";

import api from "store/api/api";
import CircularLoader from "components/SoftDialog/Loader";

function NewPassword() {
  const navigate = useNavigate();
  const [error, setError] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [form, setForm] = React.useState({
    newPassword: "",
    confirmPassword: "",
    email: "",
  });
  const [loading, setLoading] = React.useState(false);

  const email = React.useMemo(() => localStorage.getItem("resetEmail"), []);

  const handleChange = (e) =>
    setForm((data) => ({ ...data, [e.target.name]: e.target.value }));

  const validateForm = () => {
    const requiredFields = {
      "New Password": form.newPassword,
      "Confirm Password": form.confirmPassword,
    };

    const emptyField = Object.entries(requiredFields).find(
      ([_, value]) => !value
    );
    if (emptyField) {
      setError(true);
      setLoading(false);
      return false;
    }
    return true;
  };

  const submitForm = async () => {
    setLoading(true);
    if (!validateForm()) return;

    if (form.newPassword === form.confirmPassword) {
      const data = {
        email,
        password: form.newPassword,
      };
      setError(false);
      await api.changeForgotPassword(data);
      navigate("/authentication/sign-in");
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const toggleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  return (
    <CoverLayout
      title="New Password"
      color="primary"
      description="Your new password must be unique from those previously used."
      image={curved9}
    >
      <SoftBox component="form" role="form">
        {error && (
          <SoftBox mb={2}>
            <SoftTypography
              variant="caption"
              color="warning"
              fontWeight="medium"
            >
              Passwords do not match. Please try again.
            </SoftTypography>
          </SoftBox>
        )}
        <SoftBox mb={3} sx={{ position: "relative" }}>
          <SoftInput
            value={form.newPassword}
            name="newPassword"
            onChange={handleChange}
            type={showNewPassword ? "text" : "password"}
            placeholder="New Password"
            sx={{
              padding: "16px 42px 16px 4px",
              "&.Mui-focused": {
                padding: "16px 42px 16px 4px",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#737373 !important",
                fontSize: "16px",
                opacity: 1,
              },
              "& .MuiInputBase-input": {
                fontSize: "16px",
              },
            }}
            icon={{ component: <LockIcon />, direction: "left" }}
          />
          <SoftBox
            onClick={toggleNewPasswordVisibility}
            sx={{
              position: "absolute",
              right: "12px",
              top: "55%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              color: "text.main",
            }}
          >
            {showNewPassword ? (
              <VscEyeClosed size={20} />
            ) : (
              <VscEye size={20} />
            )}
          </SoftBox>
        </SoftBox>
        <SoftBox mb={3} sx={{ position: "relative" }}>
          <SoftInput
            value={form.confirmPassword}
            name="confirmPassword"
            onChange={handleChange}
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
            sx={{
              padding: "16px 42px 16px 4px",
              "&.Mui-focused": {
                padding: "16px 42px 16px 4px",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#737373 !important",
                fontSize: "16px",
                opacity: 1,
              },
              "& .MuiInputBase-input": {
                fontSize: "16px",
              },
            }}
            icon={{ component: <LockIcon />, direction: "left" }}
          />
          <SoftBox
            onClick={toggleConfirmPasswordVisibility}
            sx={{
              position: "absolute",
              right: "12px",
              top: "55%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              color: "text.main",
            }}
          >
            {showConfirmPassword ? (
              <VscEyeClosed size={20} />
            ) : (
              <VscEye size={20} />
            )}
          </SoftBox>
        </SoftBox>

        <SoftBox mt={5} mb={1} width="75%">
          <SoftButton
            onClick={submitForm}
            variant="gradient"
            color="primary"
            sx={{
              fontSize: "16px",
              padding: "14px 24px",
              fontWeight: "bold",
            }}
            fullWidth
          >
            {loading == true ? <CircularLoader type="small" /> : "Save"}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default NewPassword;
